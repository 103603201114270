import React from 'react';
import ProfileMenuItem from './ProfileMenuItem';
import AvatarIcon from '../icons/avatar';
import MenuIcon from '../../assets/menu.svg';
import * as styles from './ProfileMenuDropdown.module.scss';

const ProfileMenuDropdown = ({ items, avatarOn, zIndex }) => {
  const zIndexVars = {
    '--z-index-profile-dropdown': zIndex,
    '--z-index-profile-dropdown-top': zIndex + 1,
  };

  return (
    <div className={styles.dropdown} style={zIndexVars} tabIndex={0} role="button" aria-label="Profile Menu Dropdown">
      <div className={styles['profile-icons']}>
        <div className={styles['menu-icon']}>
          <img src={MenuIcon} />
        </div>
        <div className={`${styles['avatar-icon']} ${avatarOn ? styles.avatarOn : ''}`}>
          <AvatarIcon />
        </div>
      </div>
      <div className={styles['profile-menu']}>
        {items.map((item, index) => (
          <ProfileMenuItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default ProfileMenuDropdown;
