.footer-accordion {
  font-family: var(--font-primary), sans-serif;
}

.footer-accordion ul {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 24px 56px;
}

.footer-accordion li {
  padding: 8px;
  font-size: 16px;
  line-height: 18px;
  animation: sweep 0.5s ease-in-out;
}

.footer-accordion__label {
  background: var(--color-blue-dark);
  width: 100%;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 26px 16px 17px;
  line-height: 27px;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-secondary);
  cursor: pointer;
  border-bottom: rgba(196, 218, 233, 0.6) 1px solid;
}

.footer-accordion__label:hover {
  color: var(--color-primary);
}

.open ul {
  display: flex;
}

.open,
.open .footer-accordion__label {
  background-color: rgba(228, 236, 241, 1);
}

.footer-accordion__icon {
  height: 20px;
  width: 20px;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}
