import React from 'react';
import { render } from 'react-dom';
import App from './App';
import './index.css';
const rootEl = document.getElementById('root');
rootEl.style.height = '100%';
rootEl.style.display = 'flex';
rootEl.style['flex-direction'] = 'column';
render(<App />, rootEl);

if (module.hot) {
  module.hot.accept();
}
