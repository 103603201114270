import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AspectRatioContainer = styled.div`
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ${(props) => (props.height / props.width) * 100}%;
  }
`;

const AspectRatioContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export function AspectRatio({ width, height, children, ...next }) {
  return (
    <AspectRatioContainer width={width} height={height} {...next}>
      <AspectRatioContent>{children}</AspectRatioContent>
    </AspectRatioContainer>
  );
}

AspectRatio.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.node,
};

AspectRatio.defaultProps = {
  children: [],
};

export default AspectRatio;
