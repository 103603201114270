import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class TextLoader extends Component {
  static propTypes = {
    interval: PropTypes.number,
  };

  static defaultProps = {
    interval: 750,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '.',
    };
  }

  componentDidMount() {
    const { interval } = this.props;
    this.interval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.value === '.') {
          prevState.value = '..';
        } else if (prevState.value === '..') {
          prevState.value = '...';
        } else if (prevState.value === '...') {
          prevState.value = '.';
        }
        return prevState;
      });
    }, interval);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { value } = this.state;
    return <span>{value}</span>;
  }
}
export default TextLoader;
