import React from 'react';
import HeaderAccordion from './HeaderAccordion';
import Hyperlink from '../Link';
import * as styles from './HeaderDrawerMenu.module.scss';

const HeaderDrawerMenu = ({ navigation }) => {
  return (
    <>
      {navigation.map(({ props: { navigationItems, title, identifier, slug = '' } }, index) =>
        navigationItems ? (
          <HeaderAccordion key={index} parentNavGroupTitle={title} navigationItems={navigationItems} />
        ) : (
          <div key={index} className={`${styles['header__drawer-link']}`}>
            <Hyperlink
              href={slug}
              id={identifier}
              dataNavtype="header"
              dataNavgroup={title}
              className={`${styles['gtm-nav-click']}`}
            >
              {title}
            </Hyperlink>
          </div>
        ),
      )}
    </>
  );
};

export default HeaderDrawerMenu;
