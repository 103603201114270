import React, { useEffect } from 'react';
import XIcon from '../icons/x';
import * as styles from './Drawer.module.scss';

const Drawer = ({
  isOpen,
  zIndex = 20,
  backgroundColor = 'white',
  position = 'left',
  onClose,
  children,
  ...restProps
}) => {
  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.code === 'Escape' && isOpen) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keyup', handleKeyUp);
    } else {
      document.removeEventListener('keyup', handleKeyUp);
    }

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isOpen]);

  useEffect(() => {}, [position]);

  const zIndexVars = {
    '--z-index-profile-drawer': zIndex,
    '--z-index-profile-drawer-top': zIndex + 1,
  };
  return (
    <>
      <div
        className={`${styles.backdrop} ${isOpen ? styles.visible : ''}`}
        style={zIndexVars}
        onClick={onClose}
        aria-hidden="true"
      />
      <div
        className={`${styles.drawer} 
          ${styles[position]} 
          ${isOpen ? styles.slideIn : ''} 
          ${styles[backgroundColor]}`}
        style={zIndexVars}
        {...restProps}
      >
        {children}
      </div>
      <button
        className={`${styles.close} ${styles[position]} ${isOpen ? styles.slideIn : ''}`}
        style={zIndexVars}
        aria-label="Close Drawer"
        onClick={onClose}
      >
        <XIcon />
      </button>
    </>
  );
};

export default Drawer;
