.social-icon {
  height: 20px;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  margin: 0 8px;
  display: flex;
}
