.footer__socials--mobile {
  padding: 24px 0;
  border-bottom: rgba(196, 218, 233, 0.6) 1px solid;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer__socials--desktop {
  display: none;
}

.footer__accreditations {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  gap: 16px;
}

.footer__equal-housing {
  height: 45px;
  width: 45px;
}

.footer__logo {
  min-width: 220px;
  width: 220px;
  margin: 24px auto 24px;
}

.footer__copyright {
  display: flex;
  flex-direction: column;
  font-family: var(--font-primary), sans-serif;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  margin-bottom: 32px;
}

.footer__copyright p {
  margin: 0;
  line-height: 14px;
}

@media (min-width: 768px) {
  .footer__legal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 36px 0;
    width: 100%;
  }

  .footer__accreditations,
  .footer__copyright,
  .footer__logo {
    margin: 0;
  }
}

@media (min-width: 992px) {
  .footer__legal--collapsed {
    flex-direction: column;
    margin: 24px 0;
  }

  .footer__legal--collapsed .footer__logo {
    margin-bottom: 24px;
  }

  .footer__legal--collapsed .footer__copyright {
    margin-bottom: 24px;
  }
}

@media (min-width: 1160px) {
  .footer__socials--mobile {
    display: none;
  }

  .footer__socials--desktop {
    display: inline-flex;
  }

  .footer__socials--hide {
    display: none;
  }

  .footer__socials--show {
    display: flex;
  }

  .footer__copyright {
    align-items: center;
    gap: 4px;
  }

  .footer__copyright--collapsed {
    flex-direction: column;
  }

  .footer__legal--collapsed {
    flex-direction: row;
    margin: 24px 0;
  }
}
