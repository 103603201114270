import React from 'react';
import Legal from './Legal';
import Socials from './Socials';

import EqualHousing from '../../assets/equalHousing.svg';
import Logo from 'jsx:../../assets/logo.svg';
import styles from './FooterNavigationInfo.module.scss';

const Footer = ({ collapsed }) => {
  return (
    <>
      <div className={`${styles['footer__socials--mobile']} ${collapsed ? styles['footer__socials--show'] : ''}`}>
        <Socials resolution="mobile" />
      </div>
      <div className={`${styles.footer__legal} ${collapsed ? styles['footer__legal--collapsed'] : ''}`}>
        <div className={`${styles.footer__logo}`}>
          <a href="/" target="_self" aria-label="Home" data-navtype="header" data-navgroup="home">
            <Logo />
          </a>
        </div>
        <div className={`${styles.footer__copyright} ${collapsed ? styles['footer__copyright--collapsed'] : ''}`}>
          <p>
            Invitation Homes Inc. © {new Date().getFullYear()} All Rights Reserved. <br />
          </p>
          <div>
            <Legal />
          </div>
        </div>

        <div className={`${styles['footer__socials--desktop']} ${collapsed ? styles['footer__socials--hide'] : ''}`}>
          <Socials resolution="desktop" />
        </div>

        <div className={`${styles['footer__accreditations']}`}>
          <iframe
            title="bbb-accreditation"
            src="https://seal-dallas.bbb.org/logo/ruhzbas/iframe/invitation-homes-90466743.html"
            width="100"
            height="45"
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
            scrolling="no"
          />
          <div className={`${styles['footer__equal-housing']}`}>
            <img src={EqualHousing} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
