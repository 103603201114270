import { Component } from 'react';
import { withServices } from '@invitation-homes/styled-ui/lib/providers/Services';
import PropTypes from 'prop-types';
import qs from 'qs';
import { logoutLink, loginLink } from '../utils/profileLinks';

class EnsureAuthentication extends Component {
  state = {
    loaded: false,
    isVerified: false,
  };

  async componentDidMount() {
    const { auth, redirectMessage, redirectTo, redirectFor, verificationRequired } = this.props;
    const grant = await auth.grant({ redirectMessage });
    const user = await auth.user(true);
    const isDisabled = !!user && user.status === 'disabled';
    const redirectRequire = verificationRequired ? verificationRequired.join() : '';
    const urlParams = qs.stringify({
      redirect_to: redirectTo,
      redirect_for: redirectFor,
      redirect_uri: window.location.href,
      redirect_require: redirectRequire,
      provider_id: process.env.REACT_APP_AUTH_PROVIDER_ID || '',
      client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET || '',
      from_self_tour: true,
    });

    if (isDisabled) {
      window.location.href = logoutLink();
    }

    if (grant && !grant.success && grant.redirect_uri) {
      window.location.href = loginLink(urlParams);
    }

    if (!user) {
      window.location.href = loginLink();
    }

    this.setState({ loaded: true, grant });
  }

  render() {
    const { loaded, grant } = this.state;

    if (!loaded || (grant && !grant.success)) {
      return null;
    }

    return this.props.children;
  }
}

EnsureAuthentication.propTypes = {
  redirectMessage: PropTypes.string,
  auth: PropTypes.object.isRequired,
};

export default withServices(['auth', 'storage'])(EnsureAuthentication);
