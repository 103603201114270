import React from 'react';
import styled from 'styled-components';

import AspectRatio from '@invitation-homes/styled-ui/lib/components/Common/AspectRatio';
// components

const UnitImageLarge = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  cursor: default;

  &:after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.13) 70%, #000000 95%);
    content: '';
    display: block;
  }
`;

export default ({ image }) => (
  <AspectRatio width={1024} height={551}>
    <UnitImageLarge image={image} />
  </AspectRatio>
);
