import React, { useEffect, useRef, useState } from 'react';
import styles from './CookieSettings.module.scss';

const CookieSettings = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const oneTrustButton = useRef(null);

  const cookiePreferenceClick = () => {
    if (oneTrustButton.current) {
      oneTrustButton.current.click();
    }
  };

  const enterCallback = (callback) => {
    return (event) => {
      if (['Enter'].includes(event.key) && callback) {
        callback();
      }
    };
  };

  useEffect(() => {
    if (window && window.Optanon) {
      setScriptLoaded(true);
    }
  }, []);

  return (
    <div id="cookie-preference" className={`${styles['cookie-preference']}`} data-loaded={scriptLoaded}>
      {' | '}
      <div
        className={`${styles.hyperlink}`}
        id="cookie-preference__link"
        data-navtype="footer"
        data-navgroup="Cookie Preference"
        onClick={cookiePreferenceClick}
        onKeyDown={enterCallback(cookiePreferenceClick)}
        role="button"
        tabIndex="0"
      >
        Cookie Preference
      </div>
      <button ref={oneTrustButton} id="ot-sdk-btn" className={`${styles['ot-sdk-show-settings']}`}>
        Cookie Preference
      </button>
    </div>
  );
};

export default CookieSettings;
