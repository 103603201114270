.dropdown {
  display: block;
}
.drawer {
  display: none;
}

@media (max-width: 1159px) {
  .dropdown {
    display: none;
  }
  .drawer {
    display: block;
    padding: 0px 4px;
    height: 24px;
  }
}
