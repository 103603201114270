import React, { useState, useCallback } from 'react';
import Item from './ProfileMenuItem';

import Drawer from './Drawer';
import UserProfileIcon from '../../assets/userProfile.svg';
import * as styles from './ProfileMenuDrawer.module.scss';

const ProfileMenu = ({ items, isOpen: initialIsOpen = false, avatarOn = false, zIndex = 20 }) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const zIndexVars = {
    '--z-index-profile-drawer': zIndex,
    '--z-index-profile-drawer-top': zIndex + 1,
  };

  return (
    <>
      <button
        className={`${styles.avatar} ${avatarOn ? styles.avatarOn : ''}`}
        style={zIndexVars}
        aria-label="Open Profile Drawer"
        onClick={handleOpen}
      >
        <img src={UserProfileIcon} />
      </button>

      <Drawer isOpen={isOpen} position="right" backgroundColor="primary-dark" onClose={handleClose} zIndex={zIndex}>
        <div className={`${styles['profile-menu']}`}>
          {items.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default ProfileMenu;
