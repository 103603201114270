/* eslint-disable no-underscore-dangle */
import React, { useCallback, useState, useEffect } from 'react';
import { withService } from './Services';

export function withUser(Wrapped, appearWhileLoading = false) {
  return withService('auth')(function UserComponent(props) {
    const { service: auth } = props;
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const onLogout = useCallback(() => {
      setUser(false);
      setLoading(false);
      localStorage.clear();
    });

    const onChange = useCallback((user) => {
      if (user.authenticated === false) {
        setUser(false);
        return;
      }

      setUser({
        ...user,
      });
    });

    useEffect(() => {
      auth
        .user()
        .then((data) => {
          setUser(data);
          setLoading(false);
        })
        .catch((err) => {
          setUser(false);
          setLoading(false);
          setError(err);
        });

      auth.emitter.on('logout', onLogout);
      auth.emitter.on('auth', onChange);

      return () => {
        auth.emitter.off('logout', onLogout);
        auth.emitter.off('auth', onChange);
      };
    }, []);

    return loading && !appearWhileLoading ? null : (
      <Wrapped {...props} auth={auth} user={user} userLoading={loading} userError={error} />
    );
  });
}

export default withUser;
