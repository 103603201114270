import mitt from 'mitt';
import CustomerService from '../customer-service';
import { toUser, toUserFavorite } from './customer-service-adapter';
import { clientSideTokenProvider } from './token-provider';
import { Authenticator } from './authenticator';
export class AuthClient {
  customerService;
  authenticator;
  emitter;
  logger;
  _user = false;
  _promise = null;
  constructor(authClientConfiguration) {
    this.customerService = new CustomerService(authClientConfiguration.customerServicesBaseUrl);
    this.authenticator = new Authenticator({
      customerWebsiteBaseUrl: authClientConfiguration.customerWebsiteBaseUrl,
      tokenProvider: authClientConfiguration.tokenProvider || clientSideTokenProvider,
    });
    this.emitter = authClientConfiguration.emitter || mitt();
    this.logger = console;
  }
  async user(forceLoad = false) {
    this.logger.info(`AuthClient:user called forceLoad: ${forceLoad}`);
    if (!forceLoad && this._promise instanceof Promise) {
      return this._promise;
    } else if (forceLoad || !this._user) {
      this._promise = this.fetchProfile()
        .then((user) => {
          if (user) {
            this._user = user;
          }
          return user;
        })
        .catch((error) => {
          this.logger.error('AuthClient.user(): failed to fetch user', error);
          return false;
        });
      return this._promise;
    }
    return Promise.resolve(this._user);
  }
  async fetchProfile() {
    this.logger.info(`AuthClient:fetchProfile called...`);
    const authentication = await this.authenticator.getAuthentication();
    if (authentication.idToken) {
      const getTrustedStatus = this.customerService.getTrustedStatus(authentication.idToken, authentication.customerId);
      const getCustomer = this.customerService.getCustomer(authentication.idToken, authentication.customerId);
      const getFavoriteProperties = this.customerService.getFavoriteProperties(
        authentication.idToken,
        authentication.customerId,
      );
      return Promise.all([getCustomer, getFavoriteProperties, getTrustedStatus]).then(
        ([customerPartial, favoriteProperties, trustedStatus]) => {
          const customer = {
            ...customerPartial,
            trusted: trustedStatus.trusted,
            trusted_at: trustedStatus.trusted_at,
          };
          return toUser(authentication.customerId, customer, favoriteProperties);
        },
      );
    }
    return false;
  }
  async saveSearch(saveSearchRequest) {
    this.logger.info(`AuthClient:saveSearch called... payload: ${JSON.stringify(saveSearchRequest)}`);
    const authentication = await this.authenticator.getAuthentication();
    if (authentication.idToken) {
      await this.customerService.saveSearch(authentication.idToken, authentication.customerId, saveSearchRequest);
    }
  }
  async saveFavoritedHome(saveFavoriteRequest, emit = true) {
    this.logger.info(
      `AuthClient:saveFavoritedHome called... saveFavoriteRequest: ${JSON.stringify(
        saveFavoriteRequest,
      )}, emit: ${emit}`,
    );
    const puCode = `${saveFavoriteRequest.property_code}-${saveFavoriteRequest.unit_code}`;
    const authentication = await this.authenticator.getAuthentication();
    if (authentication.idToken) {
      const result = await this.customerService
        .addFavoriteProperty(authentication.idToken, authentication.customerId, puCode)
        .then((favoriteProperty) => {
          const userFavorite = toUserFavorite(favoriteProperty);
          this.favoriteAdded(userFavorite, emit);
          return userFavorite;
        });
      return result;
    }
    return {};
  }
  // Rating a favorited property is not a thing with the new customer service.  But this function still being
  // called in the PLS code.  Leaving this as a noop until that code is removed from PLS
  async updateFavoritedHome(favoriteId, rating, emit = true) {
    this.logger.info(
      `AuthClient:updateFavoritedHome called... favoriteId: ${favoriteId}, rating: ${rating}, emit: ${emit}`,
    );
    return { success: true };
  }
  async removeFavoritedHome(favoriteId, emit = true) {
    this.logger.info(`AuthClient:removeFavoritedHome called... favoriteId: ${favoriteId}, emit: ${emit}`);
    const authentication = await this.authenticator.getAuthentication();
    if (authentication.idToken) {
      await this.customerService
        .removeFavoriteProperty(authentication.idToken, authentication.customerId, favoriteId)
        .then(() => this.favoriteRemoved(favoriteId, emit));
      return { success: true };
    }
    return { success: false };
  }
  favoriteAdded(userFavorite, emit) {
    this._user.favorites.push(userFavorite);
    if (emit) {
      this.emitter.emit('auth', this._user);
    }
  }
  favoriteRemoved(favoriteId, emit) {
    this._user.favorites = this._user.favorites.filter((favorite) => favorite.id !== favoriteId);
    if (emit) {
      this.emitter.emit('auth', this._user);
    }
  }
  async isAuthenticated() {
    this.logger.info(`AuthClient:isAuthenticated called...`);
    try {
      await this.authenticator.getAuthentication();
      return true;
    } catch {
      return false;
    }
  }
  isTrusted() {
    this.logger.info(`AuthClient:isTrusted called...`);
    if (this._user instanceof Promise) {
      return false;
    }
    return (
      this._user && this._user.trusted && this._user.status_sms === 'verified' && this._user.status_email === 'verified'
    );
  }
  async grant({ returnUser = false, redirectMessage = '' } = {}) {
    this.logger.info(`AuthClient:grant called... returnUser: ${returnUser}, redirectMessage: ${redirectMessage}`);
    if (this._user instanceof Promise) {
      return { success: false };
    }
    return { success: await this.isAuthenticated() };
  }
  // The remaining functions were on the legacy auth client, but  are
  // not used by PLS or Self Show
  isVerified() {
    this.logger.error(`AuthClient:isVerified called...`);
    throw new Error('Unsupported Operation: isVerified');
  }
  beginVerification(what, data) {
    this.logger.error(`AuthClient:beginVerification called... what: ${what}, data: ${data}`);
    throw new Error('Unsupported Operation: beginVerification');
  }
  completeVerification(what, token, data) {
    this.logger.error(`AuthClient:completeVerification called... what: ${what}, token: ${token}, data: ${data}`);
    throw new Error('Unsupported Operation: completeVerification');
  }
  assertAuthenticated(message) {
    this.logger.error(`AuthClient:assertAuthenticated called... message: ${message}`);
    throw new Error('Unsupported Operation: assertAuthenticated');
  }
  login(payload = {}) {
    this.logger.error(`AuthClient:login called... payload: ${payload}`);
    throw new Error('Unsupported Operation: login');
  }
  logout() {
    this.logger.error(`AuthClient:logout called...`);
    throw new Error('Unsupported Operation: logout');
  }
  redirect(options = {}) {
    this.logger.error(`AuthClient:redirect called... options: ${JSON.stringify(options)}`);
    throw new Error('Unsupported Operation: redirect');
  }
  register(options) {
    this.logger.error(`AuthClient:register called... options: ${JSON.stringify(options)}`);
    throw new Error('Unsupported Operation: register');
  }
  resendVerification(what, data) {
    this.logger.error(`AuthClient:resendVerification called... what: ${what}, data: ${JSON.stringify(data)}`);
    throw new Error('Unsupported Operation: resendVerification');
  }
  resetPassword(data, options) {
    this.logger.error(
      `AuthClient:resetPassword called... data: ${JSON.stringify(data)}, options: ${JSON.stringify(options)}`,
    );
    throw new Error('Unsupported Operation: resetPassword');
  }
  getFavoritedHome(favoriteId) {
    this.logger.error(`AuthClient:getFavoritedHome called... favoriteId: ${favoriteId}`);
    throw new Error('Unsupported Operation: getFavoritedHome');
  }
  getFavoritedHomes() {
    this.logger.error(`AuthClient:getFavoritedHomes called...`);
    throw new Error('Unsupported Operation: getFavoritedHomes');
  }
  getSavedSearch(searchId) {
    this.logger.error(`AuthClient:getSavedSearch called... searchId: ${searchId}`);
    throw new Error('Unsupported Operation: getSavedSearch');
  }
  getSavedSearches(assert) {
    this.logger.error(`AuthClient:getSavedSearches called... assert: ${assert}`);
    throw new Error('Unsupported Operation: getSavedSearches');
  }
  updateSearch(id, payload) {
    this.logger.error(`AuthClient:updateSearch called... id: ${id}, payload: ${JSON.stringify(payload)}`);
    throw new Error('Unsupported Operation: updateSearch');
  }
  removeSearch(id) {
    this.logger.error(`AuthClient:removeSearch called... id: ${id}`);
    throw new Error('Unsupported Operation: removeSearch');
  }
  startPolling(interval) {
    this.logger.error(`AuthClient:startPolling called... interval: ${interval}`);
    throw new Error('Unsupported Operation: startPolling');
  }
  store(grant, user) {
    this.logger.error(`AuthClient:store called... grant: ${JSON.stringify(grant)}, user: ${JSON.stringify(user)}`);
    throw new Error('Unsupported Operation: store');
  }
  updateLocalUser(user) {
    this.logger.error(`AuthClient:updateLocalUser called... user: ${JSON.stringify(user)}`);
    throw new Error('Unsupported Operation: updateLocalUser');
  }
  updateProfile(data, options) {
    this.logger.error(
      `AuthClient:updateProfile called... data: ${JSON.stringify(data)}, options: ${JSON.stringify(options)}`,
    );
    throw new Error('Unsupported Operation: updateProfile');
  }
}
