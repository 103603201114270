import React, { Fragment } from 'react';
import SelfShowingLayout from '../../layouts/SelfShowing';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import selfTourHouse from '../../assets/self-tour-house.svg';
import withTheme from '@material-ui/styles/withTheme';
import { contactUsLink } from '../../../src/utils/profileLinks';

const Heading = withTheme(styled.h3`
  width: 100%;
  padding: 0;
  margin: 1em 0;
  font-size: ${(props) => props.theme.typography.pxToRem(25)};
  color: ${(props) => props.theme.palette.ih.silver_chalice};
  -webkit-font-smoothing: antialiased;
  color: inherit;
  font-weight: normal;
`);

const OuterBox = styled(Box)`
  width: 577px;
  padding: 24px;
  color: #4c4c4c;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 4px;

  @media (max-width: 767px) {
    max-width: 577px;
    width: 100%;
    padding: 16px;
  }
`;

const ErrorHeader = styled.h2`
  font-size: 16px;
  color: #d33841;
  margin: 0;
`;

const InnerBox = styled(Box)`
  padding: 16px;
  margin-top: 16px;
  background-color: #f6f6f6;
  border-radius: 4px;
`;

const ContactUsLink = withTheme(styled.a`
  cursor: pointer;
  color: #217106;
  font-weight: bold;
  text-decoration: underline;
`);

const createListItem = (listItemText) => {
  return (
    <ListItem style={{ padding: '0' }}>
      <ListItemIcon style={{ minWidth: '32px' }}>
        <CheckIcon />
      </ListItemIcon>
      <ListItemText primary={listItemText} />
    </ListItem>
  );
};

const renderRetryAllowed = (unitId) => {
  return (
    <Fragment>
      Some information provided could not be verified.{' '}
      <ContactUsLink href={`${process.env.REACT_APP_BASE_URL}/schedule-showing/${unitId}`}>
        Please try again.
      </ContactUsLink>
    </Fragment>
  );
};

const renderRetryNotAllowed = () => {
  return (
    <Fragment>
      We were unable to verify the information provided. To schedule an in-person tour for this property, please{' '}
      <ContactUsLink href={contactUsLink()}>contact us</ContactUsLink>.
    </Fragment>
  );
};

const VerificationFailed = ({ unitId, allowRetry }) => {
  return (
    <SelfShowingLayout px={[20, 30, 60]} py={40}>
      <Grid container xs={12} justify="center">
        <Box style={{ width: '100%' }}>
          <Heading>My Self-Tours</Heading>
        </Box>
        <OuterBox>
          <Box style={{ textAlign: 'center' }}>
            <img src={selfTourHouse} alt="House" />
            <ErrorHeader>
              <ErrorIcon style={{ verticalAlign: 'text-top' }} /> Unable to schedule your Self-Tour
            </ErrorHeader>
            {allowRetry ? renderRetryAllowed(unitId) : renderRetryNotAllowed()}
          </Box>
          {allowRetry && (
            <InnerBox>
              <List dense={true}>
                {createListItem('Ensure the name provided matches the uploaded identification.')}
                {createListItem('Provide a default email address.')}
                {createListItem('Verify the correct address and date of birth were entered.')}
                {createListItem('Confirm the document is valid and photos are fully visible.')}
              </List>
              If you continue to experience issues, please{' '}
              <ContactUsLink href={contactUsLink()}>contact us</ContactUsLink> to schedule an in-person tour.
            </InnerBox>
          )}
        </OuterBox>
      </Grid>
    </SelfShowingLayout>
  );
};

export default VerificationFailed;
