import axios from 'axios';
const THIRTY_DAYS_IN_MILLISECONDS = 2592000000;
export default class CustomerService {
  customerServicesBaseUrl;
  logger;
  constructor(customerServicesBaseUrl) {
    this.logger = console;
    this.customerServicesBaseUrl = customerServicesBaseUrl;
  }
  async getCustomer(idToken, customerId) {
    this.logger.info(`getCustomer(): with id ${customerId}`);
    const customer = await axios
      .get(`${this.customerServicesBaseUrl}/${customerId}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        this.logger.info(`getCustomer(): response: ${JSON.stringify(response.data)}`);
        return response.data;
      })
      .catch((error) => {
        this.logger.error(`getCustomer(): error: ${error.message}`);
        throw error;
      });
    return customer;
  }
  async getTrustedStatus(idToken, customerId, millisecondsToExpire = THIRTY_DAYS_IN_MILLISECONDS) {
    const getTrustedStatusUrl = `${this.customerServicesBaseUrl}/${customerId}/trusted-status`;
    const { trusted_at = null } = await axios
      .get(getTrustedStatusUrl, {
        headers: {
          accept: 'application/json',
          Authorization: idToken,
        },
      })
      .then((response) => {
        this.logger.info(`getTrustedStatus(): response: ${JSON.stringify(response.data)}`);
        return response.data;
      })
      .catch((error) => {
        this.logger.error(`getTrustedStatus(): error:`, error.message);
        return {
          trusted_at: null,
        };
      });
    const now = new Date().getTime();
    const expirationDate = new Date(trusted_at).getTime() + millisecondsToExpire;
    const trusted = now <= expirationDate;
    return {
      trusted_at,
      trusted,
    };
  }
  async saveSearch(idToken, customerId, saveSearchRequest) {
    this.logger.info(`saveSearch(): saving customer search: ${JSON.stringify(saveSearchRequest)}`);
    return await axios
      .post(
        `${this.customerServicesBaseUrl}/${customerId}/saved-searches`,
        {
          search_name: saveSearchRequest.name,
          search_parameters: {
            hash: saveSearchRequest.hash,
            version: saveSearchRequest.version,
          },
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      )
      .then((response) => {
        this.logger.info(`saveSearch(): response: ${JSON.stringify(response.status)}`);
      })
      .catch((error) => {
        this.logger.error(`saveSearch(): error: ${error.message}`);
        throw error;
      });
  }
  async getFavoriteProperties(idToken, customerId) {
    this.logger.info(`getFavoriteProperties(): for customer ${customerId}`);
    return await axios
      .get(`${this.customerServicesBaseUrl}/${customerId}/favorite-properties`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        this.logger.info(`getFavoriteProperties(): response: ${JSON.stringify(response.data)}`);
        return response.data;
      })
      .catch((error) => {
        this.logger.error(`getFavoriteProperties(): error: ${error.message}`);
        throw error;
      });
  }
  async addFavoriteProperty(idToken, customerId, puCode) {
    this.logger.info(`addFavoriteProperty: adding favorite property customerId: ${customerId}, puCode: ${puCode}`);
    return await axios
      .put(
        `${this.customerServicesBaseUrl}/${customerId}/favorite-properties/${puCode}`,
        {},
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      )
      .then((response) => {
        this.logger.info(`addFavoriteProperty(): response: ${JSON.stringify(response.status)}`);
        return { pu_code: puCode };
      })
      .catch((error) => {
        this.logger.error(`addFavoriteProperty(): error: ${error.message}`);
        throw error;
      });
  }
  async removeFavoriteProperty(idToken, customerId, puCode) {
    this.logger.info(`removeFavoriteProperty: removing favorite property customerId: ${customerId}, puCode: ${puCode}`);
    return await axios
      .delete(`${this.customerServicesBaseUrl}/${customerId}/favorite-properties/${puCode}`, {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        this.logger.info(`removeFavoriteProperty(): response: ${response.status}`);
      })
      .catch((error) => {
        this.logger.error(`removeFavoriteProperty(): error: ${error.message}`);
        throw error;
      });
  }
}
