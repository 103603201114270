/* eslint-disable no-undef */
import http from 'axios';

export const axios = http.create();

axios.interceptors.request.use(
  (config) => {
    let grant;
    try {
      grant = JSON.parse(localStorage.getItem('grant') || '{}');
    } catch (error) {
      grant = false;
    }

    if (grant && grant.access_token) {
      config.headers.common.Authorization = `Bearer ${grant.access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axios;
