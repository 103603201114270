import React, { useState, useEffect } from 'react';
import Drawer from './ProfileMenuDrawer';
import Dropdown from './ProfileMenuDropdown';
import * as styles from './ProfileMenu.module.scss';

const ProfileMenu = ({ items, isDrawerOpen: initialIsDrawerOpen = false, isLoggedIn = false, zIndex = 20 }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(initialIsDrawerOpen);

  const handleDrawerClose = () => setIsDrawerOpen(false);
  const handleDrawerOpen = () => setIsDrawerOpen(true);

  useEffect(() => {
    setIsDrawerOpen(initialIsDrawerOpen);
  }, [initialIsDrawerOpen]);

  return (
    <div className={`${styles['profile-menu']}`}>
      <div className={`${styles.dropdown}`}>
        <Dropdown items={items} avatarOn={isLoggedIn} zIndex={zIndex} />
      </div>
      <div className={`${styles.drawer}`}>
        <Drawer
          items={items}
          avatarOn={isLoggedIn}
          isOpen={isDrawerOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          zIndex={zIndex}
        />
      </div>
    </div>
  );
};

export default ProfileMenu;
