import React from 'react';
import axios from 'axios';
import { get, isEmpty } from 'lodash';

export default class UnitAddress extends React.Component {
  state = {
    unit: null,
    unitAddress: null,
    market: null,
    error: false,
  };
  async componentDidMount() {
    try {
      const resp = await axios.get(`/api/v1/units/${this.props.unitId}/address`);
      const unit = get(resp, 'data.unit', {});
      if (!isEmpty(unit)) {
        const streetAddress = unit.address_2 ? `${unit.address_1} ${unit.address_2}` : unit.address_1;
        const unitAddress = `${streetAddress}, ${unit.city}, ${unit.state} ${unit.zipcode}`;
        this.setState({ unit, unitAddress, market: unit.market });
      }
    } catch (error) {
      this.setState({ error: true });
    }
  }

  render() {
    return this.props.children({
      unit: this.state.unit,
      unitAddress: this.state.unitAddress,
      market: this.state.market,
      error: this.state.error,
    });
  }
}
