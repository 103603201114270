import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import http from 'axios';
import useInterval from '@use-it/interval';

import Box from '@material-ui/core/Box';
import withTheme from '@material-ui/styles/withTheme';

import { trackGTMEvent } from '../utils/gtm';
import { Flex } from '@invitation-homes/styled-ui/lib/components/Common/Containers';
// import { Button } from "@invitation-homes/styled-ui/lib/components/Button/Button";
import Button from '@material-ui/core/Button';
import { Label, Value } from './CardCommon';
import ShowingStatus from '../components/ShowingStatus';

import { canModifyShowing } from '../../lib/showing-process/can-modify-showing';

const WhiteBox = styled(Box)`
  background-color: #f6f5f6;
  border: 2px solid #cccccc4a;
  box-sizing: border-box;
  height: 100%;
`;

const ContentBox = styled(Box)`
  flex: 2;
`;

const Heading = withTheme(styled.h3`
  width: 80%;
  padding: 0;
  font-size: ${(props) => props.theme.typography.pxToRem(25)};
  color: ${(props) => props.theme.palette.ih.silver_chalice};
  -webkit-font-smoothing: antialiased;
  color: inherit;
  font-weight: normal;

  @media (max-width: 1500px) {
    font-size: ${(props) => props.theme.typography.pxToRem(20)};
  }

  @media (max-width: 980px) {
    font-size: ${(props) => props.theme.typography.pxToRem(17)};
  }
`);

let showingTracked = false;

export default ({ time, timezone, className, onCancel, data } = {}) => {
  const [loading, setLoading] = useState(false);
  const [retrying, setRetrying] = useState(false);
  const [showing, setShowing] = useState(data);

  const changeable = useMemo(() => canModifyShowing(showing), [showing]);

  function refresh() {
    setLoading(true);
    return http.get(`/api/showing/${showing.id}`).then((res) => {
      if (res && res.data) {
        if (res.data.tracked_at !== null && !showingTracked) {
          showingTracked = true;
          trackGTMEvent({
            event: 'selfShowProcess',
            eventCategory: 'Self Show Process',
            eventAction: 'Completed Showing',
          });
        }
        setShowing(res.data);
        setLoading(false);
      }
    });
  }

  function retry() {
    if (!retrying) {
      setRetrying(true);
      return http.post(`/api/showing/${data.id}/process`).then((res) => {
        if (res && res.data) {
          setShowing({ ...showing, ...res.data });
        }
        setRetrying(false);
      });
    }
  }

  useInterval(() => {
    if (!loading && showing.status !== 'Pending') {
      refresh();
    }
  }, 3000);

  useEffect(() => {
    refresh();
  }, []);

  const toEdit = `/home/${showing.id}/edit`;
  const toAccessCodeHelp = `https://www.invitationhomes.com/self-tour-entry-code/`;

  return useMemo(
    () => (
      <WhiteBox shadow p={3} className={className}>
        <Flex height="100%" column justify="space-between" align="flex-start">
          <Heading>Your Self-Tour</Heading>
          <ContentBox mb={3}>
            <Flex wrap="true" align="flex-start">
              <Box width="100%" pl={1} pr={1} flex={1}>
                <Value>
                  <Label>Date</Label>
                  {moment(time).tz(timezone).format('ddd MMMM Do')}
                </Value>
              </Box>
              <Box width="100%" pl={1} pr={1} flex={1}>
                <Value>
                  <Label>Time</Label>
                  {moment(time).tz(timezone).format('h:mm A') +
                    ' - ' +
                    moment(time).tz(timezone).add(1, 'h').format('h:mm A')}
                </Value>
              </Box>
              <Box width="100%" pl={1} pr={1} flex={1}>
                <Value>
                  <Label>Address</Label>
                  {showing.unit.address_1}
                  <br />
                  {`${showing.unit.city}, ${showing.unit.state} ${showing.unit.zipcode}`}
                </Value>
              </Box>
              <ShowingStatus
                showing={showing}
                retrying={retrying}
                failed={
                  !['Pending', 'Canceled', 'Completed'].includes(showing.status) &&
                  moment(showing.queue_job_started_at) < moment().subtract(300, 'seconds')
                }
                onRetry={() => {
                  retry();
                }}
              />
            </Flex>
          </ContentBox>
          <Box width="100%">
            <Box width="100%">
              <Button
                fullWidth={true}
                color="primary"
                variant="contained"
                href={toAccessCodeHelp}
                style={{ textAlign: 'center' }}
                target="_blank"
              >
                How to Use the Access Code
              </Button>
            </Box>
            <Box width="100%" mt={1}>
              <Button fullWidth={true} color="primary" variant="outlined" href={toEdit} disabled={!changeable}>
                Change Date & Time
              </Button>
            </Box>
            <Box width="100%" mt={1}>
              <Button
                fullWidth={true}
                color="primary"
                variant="outlined"
                disabled={!changeable}
                onClick={changeable ? onCancel : null}
              >
                Cancel Self-Tour
              </Button>
            </Box>
          </Box>
        </Flex>
      </WhiteBox>
    ),
    [showing],
  );
};
