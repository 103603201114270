import axios from 'axios';

class BaseService {
  constructor(options = {}) {
    this.options = {
      base_url: '/',
      ...options,
    };
    this.connector =
      this.options.connector ||
      axios.create({
        ...(options.connector_options || {}),
        baseURL: this.options.base_url,
      });
  }

  getOptions(config = {}) {
    return {
      ...(this.options.default_options || {}),
      ...config,
    };
  }

  getHeaders(config = {}) {
    return {
      ...config,
      ...(this.options.default_headers || {}),
    };
  }

  endpoint(path = '') {
    return `${this.options.base_url}${path}`;
  }

  request(method = 'GET', { endpoint = '', data = {}, config = {} } = {}) {
    const options = {
      ...config,
      method: typeof method === 'string' ? method.toUpperCase() : 'GET',
      data,
      url: this.endpoint(endpoint),
      headers: config.headers ? config.headers : this.getHeaders({}),
    };

    return this.connector
      .request(options)
      .then((res) => (this.options.raw ? res : res.data) || null)
      .catch((err) => {
        if (axios.isCancel(err)) {
          return null;
        }
        return Promise.reject(err);
      });
  }

  get(endpoint = '', options = {}, data = {}) {
    return this.request('get', { endpoint, data, config: options });
  }

  post(endpoint = '', data = {}, options = {}) {
    return this.request('post', { endpoint, data, config: options });
  }

  put(endpoint = '', data = {}, options = {}) {
    return this.request('put', { endpoint, data, config: options });
  }

  delete(endpoint = '', options = {}) {
    return this.request('delete', { endpoint, data: {}, config: options });
  }

  search(endpoint = '', data = {}, options = {}) {
    return this.request('search', { endpoint, data, config: options });
  }

  generateCancelToken() {
    return axios.CancelToken.source();
  }
}

module.exports = BaseService;
