import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const Spinner = styled.div`
  width: ${(props) => (props.size ? `${props.size}px` : '40px')};
  height: ${(props) => (props.size ? `${props.size}px` : '40px')};

  position: relative;
  margin: 0 auto;
`;

const Bounce = keyframes`
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
`;

const DoubleBounce = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  animation: ${Bounce} 2s infinite ease-in-out;
`;

const DoubleBounce2 = styled(DoubleBounce)`
  animation-delay: -1s;
`;

// eslint-disable-next-line react/display-name
function CircleLoader({ size }) {
  return (
    <Spinner size={size}>
      <DoubleBounce />
      <DoubleBounce2 />
    </Spinner>
  );
}
CircleLoader.propTypes = {
  size: PropTypes.number,
};
CircleLoader.defaultProps = {
  size: 40,
};

export default CircleLoader;
