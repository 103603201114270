import authClient from './auth-client';
import PLSClient from '../../lib/pls-client';
import { makeUseAxios } from 'axios-hooks';
import ShowingsService from './showings';
import emitter from './emitter';
import axios from 'axios';

// Storage service
import storage from './storage';
storage.verifyVersion();

// Showings service
const showings = new ShowingsService();

// Auth service
export const auth = authClient;

// PLS Client
export const pls = new PLSClient({
  endpoint: process.env.REACT_APP_PLS_API || 'http://localhost:8013/api',
});

const useAxios = makeUseAxios({ axios });
const useAuthAxios = makeUseAxios({ axios: auth.connector });

module.exports = {
  services: {
    auth,
    pls,
    storage,
    showings,
  },
  emitter,
  useAxios,
  useAuthAxios,
};
