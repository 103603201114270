import React from 'react';

export default function Avatar() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z"
        fill="currentColor"
      />
      <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="34">
        <path
          d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0)">
        <mask id="mask1" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="9" y="9" width="16" height="16">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 13C21 15.21 19.21 17 17 17C14.79 17 13 15.21 13 13C13 10.79 14.79 9 17 9C19.21 9 21 10.79 21 13ZM19 13C19 11.9 18.1 11 17 11C15.9 11 15 11.9 15 13C15 14.1 15.9 15 17 15C18.1 15 19 14.1 19 13ZM17 18C14.33 18 9 19.34 9 22V24C9 24.55 9.45 25 10 25H24C24.55 25 25 24.55 25 24V22C25 19.34 19.67 18 17 18ZM11 22.01V23H23V22C22.8 21.29 19.7 20 17 20C14.3 20 11.2 21.29 11 22.01Z"
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask1)">
          <rect x="5" y="5" width="24" height="24" fill="#E8F5E3" />
        </g>
      </g>
    </svg>
  );
}
