import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
const MILLIS_IN_A_SECOND = 1000;
const TEN_MINUTES_IN_MILLIS = 600000;
export class Authenticator {
  customerWebsiteBaseUrl;
  tokenProvider;
  logger;
  constructor(configuration) {
    this.customerWebsiteBaseUrl = configuration.customerWebsiteBaseUrl;
    this.tokenProvider = configuration.tokenProvider;
    this.logger = console;
  }
  async getAuthentication() {
    if (this.isIdTokenAboutToExpire()) {
      await this.refreshTokens();
    }
    if (this.isValidIdToken()) {
      return {
        customerId: this.getCustomerId(),
        idToken: this.tokenProvider.getIdToken(),
        refreshToken: this.tokenProvider.getRefreshToken(),
        expiresAt: this.getExpiresAt(),
      };
    }
    throw new Error('User is not authenticated');
  }
  async refreshTokens() {
    return await axios
      .get(`${this.customerWebsiteBaseUrl}/profile/api/session/refresh`, {
        withCredentials: true,
      })
      .then((response) => {
        this.logger.info(`refreshTokens() response status: ${response.status}`);
      })
      .catch((error) => {
        this.logger.error(`refreshTokens() => error message: ${error.message}`);
        throw error;
      });
  }
  isValidIdToken() {
    const idTokenDefined = !!this.tokenProvider.getIdToken();
    return idTokenDefined && !this.isIdTokenExpired();
  }
  isIdTokenExpired = (adjustmentInMillis = 0) => {
    const expiresAtInSeconds = this.getExpiresAt();
    if (expiresAtInSeconds) {
      const expiresAt = expiresAtInSeconds * MILLIS_IN_A_SECOND;
      return Date.now() >= expiresAt - adjustmentInMillis;
    }
    throw new Error('idToken is not defined');
  };
  isIdTokenAboutToExpire = () => {
    const idTokenDefined = !!this.tokenProvider.getIdToken();
    return idTokenDefined && this.isIdTokenExpired(TEN_MINUTES_IN_MILLIS);
  };
  getCustomerId = () => {
    const idToken = this.tokenProvider.getIdToken();
    if (idToken) {
      const { customer_id } = jwtDecode(idToken);
      return customer_id;
    }
    return undefined;
  };
  getExpiresAt = () => {
    const idToken = this.tokenProvider.getIdToken();
    if (idToken) {
      const { exp } = jwtDecode(idToken);
      return exp;
    }
    return undefined;
  };
}
