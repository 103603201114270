import React, { useState, useEffect, useRef } from 'react';
import HeaderDrawerMenu from './HeaderDrawerMenu';
import HeaderSubmenu from './HeaderSubmenu';
import Drawer from './Drawer';
import Hyperlink from '../Link';
import Logo from 'jsx:../../assets/logo.svg';
import MenuIcon from '../../assets/menu.svg';
import ProfileMenu from './ProfileMenu';
import * as styles from './index.module.scss';

const Header = ({ profileMenus, navigation, isLoggedIn = false, showShadow = true, drawerZIndex = 20 }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const desktopMinWidth = 1160;
  const drawerClear = useRef();

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openDrawer = () => setIsOpen(true);
  const closeDrawer = () => setIsOpen(false);
  const onActive = (index) => {
    if (navigation[Number(index)]) {
      setActiveMenuItem(index);
    }
  };

  const openSubmenu = () => {
    if (drawerClear.current) clearTimeout(drawerClear.current);
  };

  const closeSubmenu = () => {
    drawerClear.current = setTimeout(() => {
      setActiveMenuItem(false);
    }, 300);
  };

  const allowFocusLogo = isOpen ? -1 : 0;

  return (
    <>
      <header className={showShadow ? styles.shadow : ''}>
        <div className={styles.headerContent}>
          <button className={`${styles.header__toggle}`} aria-label="Open Header Drawer" onClick={openDrawer}>
            <img src={MenuIcon} />
          </button>
          <div className={`${styles.header__logo}`}>
            <a
              href="/"
              target="_self"
              aria-label="Home"
              data-navtype="header"
              data-navgroup="home"
              tabIndex={allowFocusLogo}
            >
              <Logo />
            </a>
          </div>
          <nav
            className={`${styles['header__main-navigation']}`}
            onMouseEnter={openSubmenu}
            onMouseLeave={closeSubmenu}
          >
            <ul>
              {navigation.map(({ props: { title, navigationItems, slug = '' } }, i) => (
                <li key={i}>
                  {navigationItems ? (
                    <span
                      className={`${styles['header__main-navigation--item']}`}
                      onMouseEnter={() => onActive(i)}
                      onClick={() => onActive(i)}
                      onKeyUp={() => onActive(i)}
                      onBlur={closeSubmenu}
                    >
                      {title}
                    </span>
                  ) : (
                    <Hyperlink
                      href={slug}
                      dataNavtype="header"
                      dataNavgroup={title}
                      className={`${styles['gtm-nav-click']}`}
                    >
                      {title}
                    </Hyperlink>
                  )}
                  {navigationItems && (
                    <HeaderSubmenu
                      headerSubmenuZIndex={drawerZIndex}
                      parentNavGroupTitle={title}
                      items={navigationItems}
                      visible={activeMenuItem === i}
                    />
                  )}
                </li>
              ))}
            </ul>
          </nav>
          {profileMenus ? (
            <ProfileMenu zIndex={drawerZIndex} items={profileMenus} isLoggedIn={isLoggedIn} />
          ) : (
            <div className={`${styles['profile-offset']}`} />
          )}
        </div>
      </header>
      {innerWidth < desktopMinWidth && (
        <Drawer zIndex={drawerZIndex} backgroundColor="white" isOpen={isOpen} onClose={closeDrawer}>
          <HeaderDrawerMenu navigation={navigation} />
        </Drawer>
      )}
    </>
  );
};

export default Header;
