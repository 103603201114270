import React, { Fragment } from 'react';

import withTheme from '@material-ui/styles/withTheme';

import styled from 'styled-components';
import { contactUsLink } from './profileLinks';

const TextLink = withTheme(styled.a`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: bold;
  text-decoration: unset;
`);

const ContactUsLink = withTheme(styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
  margin-left: 4px;
`);

export default {
  ERR_TU_HIGH_RISK: {
    title: 'Unable to schedule your Self-tour',
    message: (
      <Fragment>
        We were unable to verify the information provided. Please verify all entered information and try again. If you
        continue to experience this issue, please<ContactUsLink href={contactUsLink()}>contact us</ContactUsLink>.
      </Fragment>
    ),
    code: 29,
  },
  ERR_SHOWINGS_MULTIPLE_STATES: {
    title: 'Unable to schedule your Self-tour',
    message: (
      <Fragment>
        We were unable to verify the information provided. To schedule an in-person tour for this property, please
        <ContactUsLink href={contactUsLink()}>contact us</ContactUsLink>.
      </Fragment>
    ),
    code: 29,
  },
  ERR_TRUSTEV_BLOCKED: {
    title: 'Unable to schedule your Self-tour ',
    message: (
      <Fragment>
        <p>
          This error has occurred due to a built-in ad blocker or 3rd party extension on your browser. Please disable
          and try again.
        </p>
        <p>Alternately, you can add http://gateway.invitationhomes.com to your list of trusted sites.</p>
        <p>
          If you continue to experience issues after your ad blocker is disabled, please
          <ContactUsLink href={contactUsLink()}>contact us</ContactUsLink>.
        </p>
      </Fragment>
    ),
    code: 27,
  },
  ERR_TU_ERROR: {
    title: 'Unable to schedule your Self-tour',
    message: (
      <Fragment>
        We are sorry we were unable to process your request. Please try again, if you continue to experience an issue,
        please
        <ContactUsLink href={contactUsLink()}>contact us</ContactUsLink>.
      </Fragment>
    ),
    code: 29,
  },
  ERR_FAILED_TO_CREATE_SHOWING: {
    title: 'Self-Tour Not Created',
    message: (
      <Fragment>
        We are unable to process your self-tour request at this time. Please submit your information below and we can
        help connect you with a leasing professional to schedule an in-person tour. We look forward to helping you find
        your new home.
      </Fragment>
    ),
    code: 28,
    showForm: true,
  },
  ERR_SHOWINGS_IN_MULTIPLE_STATES: {
    title: 'Your Multiple Self-Tour Requests',
    message: (
      <Fragment>
        You have scheduled a tour in different states today. Please submit your information below and we can help you
        schedule your tour. We look forward to helping you find your new home.
      </Fragment>
    ),
    code: 101,
    showForm: true,
  },
  ERR_USER_NOT_ACTIVE_AND_VERIFIED: {
    title: 'Finish Setting Up Your Account',
    message: (
      <Fragment>
        Your account is not active yet. Please verify your account’s email address and phone number to continue. If you
        did not get a verification email, please{' '}
        <TextLink href={`${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}/profile/forgot-password`}>reset</TextLink> your
        account password to verify your account.
      </Fragment>
    ),
    code: 102,
    showForm: false,
  },
  ERR_TOO_MANY_SHOWINGS_PER_DAY: {
    title: 'Self-Tour Request Limit Reached',
    message: (
      <Fragment>
        Your self-tour request was unsuccessful due to a large amount of requests. Please submit your information below
        and we can help you schedule your tour. We look forward to helping you find a new home.
      </Fragment>
    ),
    code: 103,
    showForm: true,
  },
  ERR_UNIT_NOT_AVAILABLE: {
    title: 'This Home is Unavailable - Find Another Today!',
    message: (
      <Fragment>
        This home is no longer available for lease. Please visit{' '}
        <TextLink href="https://lease.invitationhomes.com">Invitation Homes</TextLink> to find another one of our
        high-quality homes that is right for you.
      </Fragment>
    ),
    code: 353,
    showForm: false,
  },
  // Catch all
  _: {
    title: 'Unknown System Error',
    message: (
      <Fragment>
        An unknown error occurred. Please <TextLink href={process.env.REACT_APP_BASE_URL}>refresh</TextLink> your
        browser window. If the problem continues, please contact our customer care team at (888) 990-4684 to be
        connected with a leasing professional who can schedule an in-person tour.
      </Fragment>
    ),
    code: 0,
    showForm: false,
  },
};
