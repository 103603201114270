.container {
  flex: 1;
  padding: 16px;
}

@media (min-width: 768px) {
  .container {
    padding: 40px 30px;
  }
}
@media (min-width: 1160px) {
  .container {
    padding: 40px 60px;
  }
}
