import React, { useState } from 'react';
import moment from 'moment-timezone';

import { Button } from '@invitation-homes/styled-ui/lib/components/Button/Button';
import { Flex } from '@invitation-homes/styled-ui/lib/components/Common/Containers';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import DayButton from '../components/DayButton';
import { StyledGrid } from '../components/CardCommon';

export default function Days({ available, timezone, initialNumShown, selected, onSelect }) {
  const [showAll, setShowAll] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={3} xs={12} justify="center" aria-role="radiogroup" id="days_block">
        {available.slice(0, showAll ? available.length : initialNumShown).map((showing, index) => {
          const date = moment(showing.date).tz(timezone);
          const active =
            index === activeIndex ||
            (selected && date.tz(timezone).startOf('day').isSame(moment(selected).tz(timezone).startOf('day')));
          return (
            <StyledGrid key={date}>
              <DayButton
                date={date}
                timezone={timezone}
                disabled={showing.disabled}
                active={active}
                role="radio"
                aria-checked={active}
                onClick={() => {
                  setActiveIndex(index);
                  onSelect(date);
                }}
                {...(index === 0 ? { id: 'first_day' } : {})}
              />
            </StyledGrid>
          );
        })}
      </Grid>
      {!showAll && (
        <Flex justify="center" mt={10}>
          <Box>
            <Button
              onClick={() => {
                setShowAll(true);
              }}
              variant="text"
              color="primary"
              style={{ fontWeight: 'bold', textTransform: 'none' }}
            >
              {'Show more days ▼'}
            </Button>
          </Box>
        </Flex>
      )}
    </div>
  );
}
