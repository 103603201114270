.ot-sdk-show-settings {
  display: none;
}

.cookie-preference {
  display: none;
}

.cookie-preference[data-loaded='true'] {
  display: inline;
}

.hyperlink {
  color: var(--color, var(--color-black));
  text-decoration: none;
  display: inline;
}

.hyperlink:hover {
  color: var(--color-primary);
  cursor: pointer;
}
