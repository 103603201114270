import React from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import clock from '../assets/clock-with-exclamation-mark.svg';
import xMark from '../assets/x-mark.svg';

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Image = styled.img`
  margin: 0 auto;
`;

const TimeoutDialog = ({ open, onClose, sessionTime = '20-minute' }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <Center>
          <Image src={clock} alt="clock" />
        </Center>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Image src={xMark} alt="x-mark" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <h2>Session has expired</h2>
        <p>
          Your {sessionTime} session has expired. To continue with scheduling a self-tour a new session will be created
          and you will be redirected back to the beginning.
        </p>
      </DialogContent>
      <DialogActions style={{ padding: '0 32px 32px 0' }}>
        <Button color="primary" variant="contained" onClick={onClose}>
          Start now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeoutDialog;
