import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import useInterval from '@use-it/interval';

import clock from '../assets/clock-with-question-mark.svg';
import xMark from '../assets/x-mark.svg';

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Image = styled.img`
  margin: 0 auto;
`;

const Paragraph = styled.p`
  b {
    color: #217106;
  }
`;

const TimeoutIn = ({ seconds }) => {
  const min = Math.floor(seconds / 60);

  return (
    <Paragraph>
      Your session will timeout in{' '}
      <b>
        {min > 0 && `${min} min`} {seconds % 60} sec.{' '}
      </b>
      Please click the button below to refresh your session.
    </Paragraph>
  );
};

const TimeoutWarningDialog = ({ open, timeout, start = Date.now(), onClose }) => {
  const [time, setTime] = useState(start);

  useEffect(() => {
    setTime(start);
  }, [start]);

  useInterval(() => {
    const addMilli = window.increaseTimeoutMilliseconds ?? 0;
    setTime(() => Date.now() + addMilli);
  }, 1000);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <Center>
          <Image src={clock} alt="clock" />
        </Center>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Image src={xMark} alt="x-mark" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <h2>Are you still here?</h2>
        <TimeoutIn seconds={moment(timeout).diff(time, 'seconds')} />
      </DialogContent>
      <DialogActions style={{ padding: '0 32px 32px 0' }}>
        <Button color="primary" variant="contained" onClick={onClose}>
          Stay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeoutWarningDialog;
