import React, { useEffect, useState } from 'react';

// Styled UI
import { withConfig } from '@invitation-homes/styled-ui/lib/providers/Config';
import makeTheme from '@invitation-homes/styled-ui-theme-ih';
import { ThemeProvider } from '@material-ui/core/styles';

// Material UI
import { BaseLayout } from '@invitation-homes/styled-ui/lib/layouts/Base';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import { mapContentfulProfileNavigation } from '../../utils/parseDocuments';
import styles from './SelfShowing.module.scss';

const theme = makeTheme();

const SelfShowing = ({ children }) => {
  const [navigationHeader, setNavigationHeader] = useState([]);
  const [profileMenu, setProfileMenu] = useState([]);
  const [footerNavigation, setFooterNavigation] = useState([]);

  function getCookie(key) {
    var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }

  useEffect(() => {
    let shouldUpdate = true;
    axios.get(`/api/v1/contentful`).then((resp) => {
      const { header, profile, footer } = resp.data;
      if (shouldUpdate) {
        setNavigationHeader(header);
        setProfileMenu(mapContentfulProfileNavigation(profile?.fields.navigationItems, true));
        setFooterNavigation(footer);
      }
    });
    return () => {
      shouldUpdate = false;
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Header navigation={navigationHeader} profileMenus={profileMenu} isLoggedIn={getCookie('accessToken')} />
      <BaseLayout>
        <div className={styles.container}>{children}</div>
      </BaseLayout>
      <Footer navigation={footerNavigation} collapsed={false} />
    </ThemeProvider>
  );
};

export default withConfig(SelfShowing);
