import React from 'react';
// import styled from "styled-components";

import SelfShowingLayout from '../layouts/SelfShowing';
// layouts

import Showing from '../containers/Showing';
import EnsureAuthenticated from '../containers/EnsureAuthenticated';
// containers
export default (props) => (
  <EnsureAuthenticated
    pathName="/login"
    redirectTo="view a showing"
    verificationRequired={['email', 'phone', 'first_name', 'last_name']}
  >
    <SelfShowingLayout p={0}>
      <Showing {...props} />
    </SelfShowingLayout>
  </EnsureAuthenticated>
);
