import React, { createContext } from 'react';

export const ConfigContext = createContext({});
export const withConfig = (Wrapped) => (props) =>
  <ConfigContext.Consumer>{(config) => <Wrapped config={config} {...props} />}</ConfigContext.Consumer>;

/** *
 * @example
 * // adds a single featureFlag to a component
 * withFeatureFlags(["feature1"])(Component)
 *
 * @example
 * // adds multiple featureFlags to a component
 * withFeatureFlags(["feature1", "feature2", "feature3"])(Component)
 *
 * @param {Array} featureFlags An array of feature flag names to use
 * @returns {Object} Enhanced React Component with a feature prop
 */
export function withFeatureFlags(featureFlags = []) {
  return (Wrapped) => (props) =>
    (
      <ConfigContext.Consumer>
        {(config) => (
          <Wrapped
            {...featureFlags.reduce(
              (obj, key) => ({
                ...obj,
                [key]: { enabled: config.featureFlags[key] || false },
              }),
              {},
            )}
          />
        )}
      </ConfigContext.Consumer>
    );
}

export default ConfigContext;
