header {
  justify-content: center;
  position: relative;
  margin: 0;
  height: 56px;
}

header.shadow {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.2);
}

header,
.headerContent,
.header__logo,
.header__logo a {
  display: flex;
  align-items: center;
}

.headerContent {
  padding: 0 16px;
  max-width: 736px;
  width: 100%;
  justify-content: space-between;
}

.header__main-navigation {
  display: none;
}

.header__main-navigation ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: var(--font-primary), sans-serif;
}

.header__main-navigation > ul > li {
  padding: 16px 48px;
}

li > .hyperlink,
.header__main-navigation--item {
  font-size: 18px;
  font-weight: 500;
}

.header__main-navigation--item {
  color: var(--color-secondary);
  cursor: pointer;
  white-space: nowrap;
}

li:focus-within .header__main-navigation--item,
.header__main-navigation--item:hover,
.header__main-navigation--item:focus {
  color: var(--color-primary);
}

.header__toggle {
  display: flex;
  flex-direction: column;
  color: var(--color-secondary);
  cursor: pointer;
  border: none;
  background: none;
  height: 20px;
  width: 20px;
  padding: 0px;
}

.header__logo {
  justify-content: center;
  height: 56px;
}

.header__logo a {
  flex: 0;
  height: 100%;
  min-width: 143.54px;
}

.header__logo img {
  width: 100%;
}

.profile-offset {
  min-width: 32px;
}

@media ((min-width: 768px) and (max-width: 1159px)) {
  .headerContent {
    margin: 0 auto;
  }
}

@media (min-width: 1160px) {
  header {
    height: 72px;
  }

  .profile-offset {
    min-width: 0px;
  }

  .headerContent {
    max-width: 1128px;
    justify-content: space-between;
  }

  .header__toggle {
    display: none;
  }

  .header__main-navigation {
    display: flex;
  }

  .header__logo {
    justify-content: flex-start;
    width: 225.56px;
  }

  .header__logo a {
    min-width: 225.56px;
  }
}

@media (min-width: 1704px) {
  .headerContent {
    max-width: 1608px;
  }
}
