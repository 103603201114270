import React from 'react';
import withTheme from '@material-ui/styles/withTheme';
import styled from 'styled-components';
import Info from '../assets/info.svg';
import Warning from '../assets/warning.svg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { updateProfileLink } from '../utils/profileLinks';

const SelfTourHeader = ({ width }) => {
  const Heading = withTheme(styled.h3`
    width: 100%;
    padding: 0;
    margin: 1em 0;
    font-size: ${(props) => props.theme.typography.pxToRem(25)};
    color: ${(props) => props.theme.palette.ih.silver_chalice};
    -webkit-font-smoothing: antialiased;
    color: inherit;
    font-weight: normal;
    max-width: 1800px;
  `);
  const Message = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px;
    background: #edf2fc;
    border-radius: 4px;
    margin-bottom: 20px;
  `;
  const TextLink = withTheme(styled.a`
    color: black;
    cursor: pointer;
    text-decoration: underline;
    color: #1e305b;
  `);

  const profileLink = updateProfileLink();

  return (
    <Box width={width ? width : '100%'}>
      <Heading mb={[15]}>My Self-Tours</Heading>
      <Message>
        <img src={Info} width="18px" height="18px" alt="" />{' '}
        <Typography
          style={{
            fontSize: '14px',
            marginLeft: '5px',
            lineHeight: '16.8px',
            fontWeight: '700',
            color: '#1E305B',
          }}
        >
          All self-tour notifications will be received based on your communication preferences.{' '}
          <TextLink href={profileLink}>Change settings</TextLink>
        </Typography>
      </Message>
      <Message style={{ background: '#FDFAE9' }}>
        <img src={Warning} width="18px" height="18px" alt="" />{' '}
        <Typography
          style={{
            fontSize: '14px',
            marginLeft: '5px',
            lineHeight: '16.8px',
            fontWeight: '700',
            color: '#5E510F',
          }}
        >
          Beware of Leasing Fraud: Invitation Homes will never ask you to share your username and password.
        </Typography>
      </Message>
    </Box>
  );
};

export default SelfTourHeader;
