import { customerWebsiteBaseUrl } from '../config';
const callbackUrl = window.location.href;

export function contactUsLink() {
  return `${customerWebsiteBaseUrl}/contact-us`;
}

export function logoutLink() {
  return `${customerWebsiteBaseUrl}/profile/logout?callback=${callbackUrl}`;
}

export function loginLink() {
  return `${customerWebsiteBaseUrl}/profile/login?callback=${callbackUrl}`;
}

export function favoritedHomesLink() {
  return `${customerWebsiteBaseUrl}/profile/dashboard`;
}

export function savedSearchesLink() {
  return `${customerWebsiteBaseUrl}/profile/dashboard`;
}

export function selfToursLink() {
  return `${customerWebsiteBaseUrl}/profile/dashboard`;
}

// TODO: need to get the cognito link for edit profile
export function updateProfileLink() {
  return `${customerWebsiteBaseUrl}/profile/dashboard`;
}
