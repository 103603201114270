import React from 'react';
import CookieSettings from './CookieSettings';
import Hyperlink from '../Link';

const Legal = () => {
  return (
    <>
      <Hyperlink
        href="/privacy"
        style={{ color: 'var(--color-black)' }}
        classes="gtm-nav-click"
        dataNavtype="footer"
        dataNavgroup="privacy"
      >
        Privacy
      </Hyperlink>
      {' | '}
      <Hyperlink
        href="/terms"
        style={{ color: 'var(--color-black)' }}
        classes="gtm-nav-click"
        dataNavtype="footer"
        dataNavgroup="terms"
      >
        Terms
      </Hyperlink>
      {' | '}
      <Hyperlink
        href="https://privacyportal-cdn.onetrust.com/dsarwebform/a8c245aa-dde1-4d57-9bf4-83a5541867e0/7be655d3-bec0-4333-9790-2292a9c69bc9.html"
        style={{ color: 'var(--color-black)' }}
        classes="gtm-nav-click"
        dataNavtype="footer"
        dataNavgroup="Do Not Sell"
      >
        Do Not Sell
      </Hyperlink>
      <CookieSettings />
    </>
  );
};

export default Legal;
