export const FEATURE_FLAGS = {};

export const customerWebsiteBaseUrl = process.env.REACT_APP_CUSTOMER_WEBSITE_URL;

export const datadogRumConfig = {
  applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
  site: process.env.REACT_APP_DD_SITE,
  service: process.env.DD_SERVICE,
  env: process.env.DD_ENV,
  version: process.env.APP_VERSION,
  sessionSampleRate: parseInt(process.env.REACT_APP_DD_SAMPLE_RATE),
  sessionReplaySampleRate: parseInt(process.env.REACT_APP_DD_REPLAY_SAMPLE_RATE),
  trackUserInteractions: process.env.REACT_APP_DD_TRACK_INTERACTIONS === 'true',
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
};
