import React from 'react';
import SelfShowingLayout from '../../layouts/SelfShowing';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import Box from '@material-ui/core/Box';
import selfTourHouse from '../../assets/self-tour-house.svg';
import withTheme from '@material-ui/styles/withTheme';
import { contactUsLink } from '../../../src/utils/profileLinks';

const Heading = withTheme(styled.h3`
  width: 100%;
  padding: 0;
  margin: 1em 0;
  font-size: ${(props) => props.theme.typography.pxToRem(25)};
  color: ${(props) => props.theme.palette.ih.silver_chalice};
  -webkit-font-smoothing: antialiased;
  color: inherit;
  font-weight: normal;
`);

const OuterBox = styled(Box)`
  width: 577px;
  padding: 24px;
  color: #4c4c4c;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 4px;

  @media (max-width: 767px) {
    max-width: 577px;
    width: 100%;
    padding: 16px;
  }
`;

const ErrorHeader = styled.h2`
  font-size: 16px;
  color: #d33841;
  margin: 0;
`;

const StyledLink = withTheme(styled.a`
  cursor: pointer;
  color: #217106;
  font-weight: bold;
  text-decoration: underline;
`);

export const PlaidIdentityVerificationError = ({ children }) => {
  return (
    <SelfShowingLayout px={[20, 30, 60]} py={40}>
      <Grid container xs={12} justify="center">
        <Box style={{ width: '100%' }}>
          <Heading>My Self-Tours</Heading>
        </Box>
        <OuterBox>
          <Box style={{ textAlign: 'center' }}>
            <img src={selfTourHouse} alt="House" />
            <ErrorHeader>
              <ErrorIcon style={{ verticalAlign: 'text-top' }} /> Unable to schedule your Self-Tour
            </ErrorHeader>
            {children}
          </Box>
        </OuterBox>
      </Grid>
    </SelfShowingLayout>
  );
};

export const ContactUsLink = () => {
  return <StyledLink href={contactUsLink()}>contact us</StyledLink>;
};
