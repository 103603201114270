import { createMuiTheme } from '@material-ui/core/styles';

export { default as Logo } from './components/Logo';
export { default as LogoIH } from './components/LogoIH';

const assets = require('./theme/assets');
const palette = require('./theme/palette');
const breakpoints = require('./theme/breakpoints');
const mixins = require('./theme/mixins');
const shadows = require('./theme/shadows');
const transitions = require('./theme/transitions');
const zIndex = require('./theme/z-index');
const spacing = require('./theme/spacing');
const typography = require('./theme/typography');

// styled-system helpers:
const space = require('./theme/space');

export const theme = (options = {}) =>
  createMuiTheme({
    status: {
      danger: 'orange',
    },
    assets,
    palette,
    breakpoints,
    mixins,
    spacing,
    space,
    shadows,
    direction: 'ltr',
    overrides: {},
    transitions,
    typography,
    zIndex,
    shape: { borderRadius: 4 },
    props: {},
    themeName: 'Invitation Homes Theme',
    ...options,
  });

export { ThemeProvider } from '@material-ui/core/styles';
export default theme;
