import React, { createContext } from 'react';

export const ServicesContext = createContext({});
export const withServices = (services) => (Wrapped) => (props) =>
  (
    <ServicesContext.Consumer>
      {(provided) => {
        return (
          <Wrapped
            {...services.reduce(
              (obj, key) => ({
                ...obj,
                [key]: provided[key] || null,
              }),
              {},
            )}
            {...props}
          />
        );
      }}
    </ServicesContext.Consumer>
  );

export const withAllServices = (Wrapped) => (props) =>
  <ServicesContext.Consumer>{(services) => <Wrapped services={services} {...props} />}</ServicesContext.Consumer>;

export const withService =
  (name, key = 'service') =>
  (Wrapped) =>
  (props) =>
    (
      <ServicesContext.Consumer>
        {(services) => <Wrapped {...{ [key === true ? name : key]: services[name] || null }} {...props} />}
      </ServicesContext.Consumer>
    );
export default ServicesContext;

export const withNamedService = (name) => withService(name, name);
