import React, { useState } from 'react';
import Hyperlink from '../Link';
import Minus from '../../assets/minus.svg';
import Plus from '../../assets/plus.svg';
import styles from './FooterAccordion.module.scss';

const FooterAccordion = ({ navigationItems, parentNavGroupTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles['footer-accordion']} ${isOpen ? styles.open : ''}`}>
      <button className={styles['footer-accordion__label']} onClick={toggleIsOpen}>
        <span>{parentNavGroupTitle}</span>
        <div className={`${styles['footer-accordion__icon']}`}>{isOpen ? <img src={Minus} /> : <img src={Plus} />}</div>
      </button>
      <ul>
        {navigationItems.map(({ props: { title, slug, identifier } }) => (
          <li key={identifier}>
            <Hyperlink
              href={slug}
              id={identifier}
              dataNavtype="footer"
              dataNavgroup={parentNavGroupTitle}
              classes="gtm-nav-click"
            >
              {title}
            </Hyperlink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterAccordion;
