import moment from 'moment';

const DEFAULT_TRUSTED_VALIDITY_DAYS = 30;

export const isTrusted = (trustedAt) => {
  const daysCustomerIsTrusted = process.env.REACT_APP_TRUSTED_VALIDITY_DAYS || DEFAULT_TRUSTED_VALIDITY_DAYS;
  const untrustedBeforeDatetime = process.env.REACT_APP_UNTRUSTED_BEFORE_DATETIME;

  if (trustedAt) {
    if (untrustedBeforeDatetime && moment(trustedAt) <= moment(untrustedBeforeDatetime)) {
      return false;
    }
    return moment(trustedAt).add(daysCustomerIsTrusted, 'days') >= moment();
  }

  return false;
};
