export const STORAGE_VERSION = process.env.REACT_APP_STORAGE_VERSION || '1.2';
export default class Storage {
  static set(name, value = null) {
    localStorage.setItem(name, value);
  }

  static get(name, fallback = false) {
    const res = localStorage.getItem(name) || fallback;
    if (res === 'false') {
      return false;
    } else if (res === 'true') {
      return true;
    }

    return res;
  }

  static setJSON(name, value = {}) {
    this.set(name, JSON.stringify(value));
  }

  static getJSON(name, fallback = false) {
    const item = this.get(name);
    try {
      return item ? JSON.parse(item) : fallback;
    } catch (e) {
      return fallback;
    }
  }

  static clear() {
    localStorage.clear();
  }

  static verifyVersion(number = STORAGE_VERSION) {
    if (Storage.get('version') !== number) {
      Storage.clear();
      Storage.set('version', number);
    }
  }
}
