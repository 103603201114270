import React from 'react';
import Hyperlink from '../Link';
import Instagram from '../../assets/instagram.svg';
import Facebook from '../../assets/facebook.svg';
import Glassdoor from '../../assets/glassdoor.svg';
import Linkedin from '../../assets/linkedin.svg';
import Youtube from '../../assets/youtube.svg';
import styles from './Socials.module.scss';

const Socials = ({ resolution }) => {
  return (
    <>
      <Hyperlink
        href="https://www.instagram.com/invitationhomes"
        id={`instagram-link-${resolution}`}
        classes="gtm-nav-click"
        dataNavtype="footer"
        dataNavgroup="instagram-link"
      >
        <div className={`${styles['social-icon']}`}>
          <img src={Instagram} />
        </div>
      </Hyperlink>

      <Hyperlink
        href="https://www.facebook.com/InvitationHomesWeb"
        id={`facebook-link-${resolution}`}
        classes="gtm-nav-click"
        dataNavtype="footer"
        dataNavgroup="facebook-link"
      >
        <div className={`${styles['social-icon']}`}>
          <img src={Facebook} />
        </div>
      </Hyperlink>

      <Hyperlink
        href="https://www.youtube.com/user/InvitationHomes"
        id={`youtube-link-${resolution}`}
        classes="gtm-nav-click"
        dataNavtype="footer"
        dataNavgroup="youtube-link"
      >
        <div className={`${styles['social-icon']}`}>
          <img src={Youtube} />
        </div>
      </Hyperlink>

      <Hyperlink
        href="https://www.linkedin.com/company/invitation-homes/"
        id={`linkedin-link-${resolution}`}
        classes="gtm-nav-click"
        dataNavtype="footer"
        dataNavgroup="linkedin-link"
      >
        <div className={`${styles['social-icon']}`}>
          <img src={Linkedin} />
        </div>
      </Hyperlink>

      <Hyperlink
        href="https://www.glassdoor.com/Overview/Working-at-Invitation-Homes-EI_IE666277.11,27.htm"
        id={`glassdoor-link-${resolution}`}
        classes="gtm-nav-click"
        dataNavtype="footer"
        dataNavgroup="glassdoor-link"
      >
        <div className={`${styles['social-icon']}`}>
          <img src={Glassdoor} />
        </div>
      </Hyperlink>
    </>
  );
};

export default Socials;
