import React from 'react';
import FooterAccordion from './FooterAccordion';
import FooterNavigationInfo from './FooterNavigationInfo';
import Hyperlink from '../Link';
import styles from './index.module.scss';

const Footer = ({ navigation, collapsed }) => {
  return (
    <footer>
      <nav className={`${styles['footer__content']}`}>
        <div className={`${styles.footer__grid} ${collapsed ? styles['footer__grid--hide'] : ''}`}>
          {navigation.map(({ props }) => (
            <div className={`${styles.footer__menu}`} key={props.title}>
              <div className={styles['footer__menu--title']}>{props.title}</div>
              <ul className={`${styles['footer__menu--links']}`}>
                {props.navigationItems?.map(({ props: { title, slug, identifier } }) => (
                  <li key={identifier}>
                    <Hyperlink
                      href={slug}
                      id={identifier}
                      classes="gtm-nav-click"
                      dataNavtype="footer"
                      dataNavgroup={props.title}
                    >
                      {title}
                    </Hyperlink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div
          className={`${styles['footer__accordion-group']} ${collapsed ? styles['footer__accordion-group--show'] : ''}`}
        >
          {navigation.map(({ props: { navigationItems = [], title } }) => (
            <FooterAccordion key={title} parentNavGroupTitle={title} navigationItems={navigationItems} />
          ))}
        </div>

        <FooterNavigationInfo collapsed={collapsed} />
      </nav>
    </footer>
  );
};

export default Footer;
