import React from 'react';
import * as styles from './ProfileMenuItemLink.module.scss';
import { hrefUrl } from '~/utils/urlParsers';

const ProfileMenuItem = ({ children, url, target = '_self', variant = 'link' }) => {
  return (
    <a
      href={hrefUrl(url)}
      className={`${styles.link} ${variant === 'padded-link' ? styles.padded : ''}`}
      target={target}
      data-navtype="profile-user"
      data-navgroup="profile"
    >
      {children}
    </a>
  );
};

export default ProfileMenuItem;
