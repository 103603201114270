.header-accordion {
  font-family: var(--font-primary), sans-serif;
  border-bottom: 2px solid var(--color-grey-100);
  font-weight: 400;
}

.header-accordion ul {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 16px 24px;
}

.header-accordion li {
  padding: 12px 24px;
  font-size: 18px;
  line-height: 19px;
  animation: sweep 0.5s ease-in-out;
}

.header-accordion__label {
  border: none;
  background: none;
  font-family: var(--font-primary), sans-serif;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 26px;
  line-height: 29px;
  font-weight: 400;
  font-size: 18px;
  color: var(--color-secondary);
  cursor: pointer;
}

.header-accordion__label:hover,
.header-accordion ul {
  background-color: var(--color-grey-50);
}

.header-accordion:hover,
.open.header-accordion {
  border-bottom: 2px solid var(--color-grey-200);
}

.open ul {
  display: flex;
}

.open .header-accordion__label:hover {
  background-color: var(--color-grey-100);
}

.open .header-accordion__label {
  color: var(--color-primary);
}

.open .header-accordion__icon {
  transform: rotate(180deg);
}

.header-accordion__icon {
  height: 20px;
  width: 20px;
  transition: transform 0.5s;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}
