import React from 'react';
import { Flex } from '@invitation-homes/styled-ui/lib/components/Common/Containers';
import { Button } from '@invitation-homes/styled-ui/lib/components/Button/Button';
import errorMessages from '../utils/errorMessages';
import styled from 'styled-components';

import { MessageContainer, MessageHeader, MessageDescription } from '../components/CardCommon';

import Contact from '../components/Contact';

const CardContainer = styled.div`
  justify-content: center;
  background-color: white;
  flex-direction: column;
  width: 100%;
  display: flex;
  box-sizing: border-box;
`;

export default ({
  message = '_',
  unitId,
  padding: { pt = 50, pb = 50, pl = 30, pr = 30 } = {
    pt: 50,
    pb: 50,
    pl: 30,
    pr: 30,
  },
}) => {
  return (
    <Flex direction="column" wrap="true" pt={pt} pb={pb} pl={pl} pr={pr}>
      <CardContainer>
        <MessageContainer>
          <MessageHeader>{errorMessages[message].title}</MessageHeader>
          <MessageDescription>
            {errorMessages[message].message ? errorMessages[message].message : errorMessages['_'].message}
          </MessageDescription>
          {errorMessages[message].showForm && <Contact unitId={unitId.unit_id} />}
          {errorMessages[message].code === 353 && (
            <Button
              color="primary"
              variant="contained"
              href={process.env.REACT_APP_PLS_URL}
              fontSize="10px"
              pt="14"
              pb="14"
              mt={15}
            >
              Search Our Homes
            </Button>
          )}
          {errorMessages[message].code !== 0 &&
            errorMessages[message].code !== 27 &&
            errorMessages[message].code !== 29 && (
              <div style={{ marginTop: '20px' }}>
                <MessageDescription>
                  Or, feel free to contact our Customer Care team at (888) 990-4684
                </MessageDescription>
              </div>
            )}
        </MessageContainer>
      </CardContainer>
    </Flex>
  );
};
