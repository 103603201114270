import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withEmitter } from '../../providers/Emitter';

export class EmitterRedirect extends Component {
  static propTypes = {
    emitter: PropTypes.object.isRequired,
  };

  state = {
    shouldRedirect: false,
    to: null,
  };

  componentDidMount() {
    const { emitter } = this.props;
    emitter.on('redirect', ({ to }) => {
      this.setState({ to, shouldRedirect: true }, () => {
        this.setState({ to: null, shouldRedirect: false });
      });
    });
  }

  render() {
    const { to, location, shouldRedirect } = this.state;
    return shouldRedirect ? (
      <Redirect
        to={{
          pathname: to,
          state: { from: location },
        }}
      />
    ) : null;
  }
}

export default withEmitter(EmitterRedirect);
