import React from 'react';
import { hrefTarget, hrefUrl } from '../../../utils/urlParsers';
import styles from './Link.module.scss';

const Hyperlink = ({ id, href, dataNavtype, dataNavgroup, ariaLabel, children, ...restProps }) => {
  const target = hrefTarget(href);

  return (
    <a
      className={`${styles.hyperlink}`}
      aria-label={ariaLabel || id}
      tabIndex={0}
      id={id}
      href={hrefUrl(href)}
      target={target}
      data-navtype={dataNavtype}
      data-navgroup={dataNavgroup}
      {...restProps}
    >
      {children}
    </a>
  );
};

export default Hyperlink;
