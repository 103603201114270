import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { withTheme } from '../util/styled';

const GlobalStyles = withTheme(createGlobalStyle`
  body, html, a, button, input {
    font-family: ${(props) => props.theme.typography.fontFamily.join(',')};
  }

  body {
    background-color: #fafafa;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    margin: 0;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    font-family: ${(props) => props.theme.typography.h1.fontFamily};
  }
`);

export const BaseLayout = ({ children, pageTitle }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
        <title>{pageTitle}</title>
      </Helmet>
      <GlobalStyles />
      {children}
    </HelmetProvider>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.any,
  pageTitle: PropTypes.string,
};

BaseLayout.defaultProps = {
  children: [],
  pageTitle: '',
};
export default BaseLayout;
