/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import colors from '@material-ui/core/colors';
import MaterialButton from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  buttonXl: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export function Button(props) {
  const classes = useStyles();
  const styledProps = {};
  if (props.color && typeof props.color === 'string' && props.color.indexOf(' ') >= 0) {
    const [clr, hue] = props.color.split(' ');
    styledProps.color = (colors[clr] && colors[clr][hue]) || 'default';
  }

  if (props.to && !props.component) {
    styledProps.component = Link;
  }

  if (props.size === 'xl') {
    styledProps.size = 'large';
    styledProps.className = props.className ? `${props.className} ${classes.buttonXl}` : classes.buttonXl;
  }

  return <MaterialButton {...props} {...styledProps} />;
}
export default Button;

Button.propTypes = {
  theme: PropTypes.object,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  size: PropTypes.string,
  component: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Button.defaultProps = {
  color: 'primary',
  size: 'medium',
  component: undefined,
  disabled: undefined,
  fullWidth: undefined,
  href: undefined,
  theme: undefined,
  to: undefined,
};

export const WarningButton = ({ children, ...next }) => (
  <Button {...next} color="red 700">
    {children}
  </Button>
);

WarningButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

WarningButton.defaultProps = {
  children: [],
};
