import Cookies from 'js-cookie';
/**
 * Auth Client can be used client or server side and depending on the
 * context, will need to retrieve the token cookies differently (e.g. document
 * object versus request object).
 *
 * This is a token provider for use in the front-end code and is the default
 * if tokenProvider is not specified.
 */
class ClientSideTokenProvider {
  getIdToken() {
    return Cookies.get('idToken');
  }
  getAccessToken() {
    return Cookies.get('accessToken');
  }
  getRefreshToken() {
    return Cookies.get('refreshToken');
  }
}
export const clientSideTokenProvider = new ClientSideTokenProvider();
