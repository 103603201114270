import { useState, useEffect } from 'react';
import axios from '../util/axios';

export function useHttp({ endpoint, connector = axios, method = 'get', config = {} }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    connector[method.toLowerCase()](endpoint, config)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          setData(err.response.data);
        }

        setError(err);
        setLoading(false);
      });
  }, [endpoint, connector]);

  return {
    data,
    error,
    loading,
  };
}
