import { BaseService } from '../utils/contracts';

export default class ShowingsService extends BaseService {
  constructor() {
    super({ base_url: '/api/showing' });
  }

  create(data = {}, options = {}) {
    return this.post('/', data, options);
  }
}
