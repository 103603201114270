.dropdown {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid var(--color-grey-100);
  border-radius: 30px;
  padding: 4px;
  -webkit-font-smoothing: antialiased;
  width: 80px;
  box-sizing: border-box;
}

.menu-icon {
  color: var(--color-secondary-dark);
  height: 20px;
  padding: 0 8px;
}

.menu-icon img {
  width: 20px;
}

.avatar-icon {
  color: var(--color-secondary-light);
  height: 34px;
}

.avatar-icon img {
  width: 34px;
}

.profile-icons {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.avatarOn {
  color: var(--color-primary);
}

.dropdown:focus-within .profile-menu,
.dropdown:hover .profile-menu,
.dropdown:active .profile-menu,
.dropdown:focus .profile-menu {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s;
}

.profile-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 152px;
  right: 0;
  top: 44px;
  padding: 24px;
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.2);
  box-sizing: unset;
  opacity: 0;
  visibility: hidden;
  transition: opacity, visibility;
  transition-delay: 0s, 0.4s;
  transition-duration: 0.4s, 0s;
  z-index: var(--z-index-profile-dropdown-top);
}
