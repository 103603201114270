import { trackGTMEvent } from '../../../src/utils/gtm';

const addressForGtm = (unit) => {
  const streetAddress = unit.address_2 ? `${unit.address_1} ${unit.address_2}` : unit.address_1;
  return `${unit.market.name}, ${unit.unit_code}, ${streetAddress}, ${unit.city}, ${unit.state} ${unit.zipcode}`;
};

const sessionComplete = (status, unit, metadata) => {
  trackGTMEvent({
    event: 'Plaid_IDV_Result',
    status,
    session_id: metadata.link_session_id,
    event_address: addressForGtm(unit),
  });
};

export const passedSession = (unit, metadata) => {
  sessionComplete('Pass', unit, metadata);
};

export const failedSession = (unit, metadata) => {
  sessionComplete('Fail', unit, metadata);
};

export const passedStep = (unit, metadata) => {
  trackGTMEvent({
    event: metadata.view_name,
    status: 'Pass',
    session_id: metadata.link_session_id,
    event_address: addressForGtm(unit),
  });
};

export const failedStep = (unit, metadata) => {
  trackGTMEvent({
    event: metadata.view_name,
    status: 'Fail',
    session_id: metadata.link_session_id,
    event_address: addressForGtm(unit),
  });
};

export const abandonedFlow = (step, unit, metadata) => {
  trackGTMEvent({
    event: step,
    status: 'Abandon',
    session_id: metadata.link_session_id,
    event_address: addressForGtm(unit),
  });
};
