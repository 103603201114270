import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@invitation-homes/styled-ui/lib/components/Button/Button';
import withTheme from '@material-ui/styles/withTheme';
const StepsNavContainer = styled.div`
  padding-top: 60px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;
const StepsNavButton = withTheme(styled(Button)`
  ${(props) =>
    (props.type === 'next' || props.type === 'submit') &&
    css`
      float: right;
      ${props.disabled ? 'cursor: not-allowed;' : null};
    `};
`);
const ShowingNav = ({
  prev,
  next,
  prevTo,
  nextTo,
  prevText,
  nextText,
  nextType = 'next',
  prevType = 'prev',
  nextDisabled,
  prevDisabled,
  handleNextClick,
  handlePrevClick,
  submitting,
}) => (
  <StepsNavContainer>
    {prev && (
      <StepsNavButton
        type={prevType}
        to={prevTo}
        disabled={prevDisabled}
        onClick={handlePrevClick}
        width={[0.4, 'auto']}
        tabIndex={0}
        variant="contained"
      >
        {prevText || 'Previous'}
      </StepsNavButton>
    )}
    {next && (
      <StepsNavButton
        id="showing_nav_next"
        type={nextType}
        to={nextTo}
        disabled={nextDisabled}
        onClick={handleNextClick}
        width={[0.4, 'auto']}
        tabIndex={0}
        role="button"
        variant="contained"
      >
        {submitting ? 'Processing...' : nextText || 'Next'}
      </StepsNavButton>
    )}
    {!prev && !next && (
      <Fragment>
        <StepsNavButton
          type={prevType || 'prev'}
          to={prevTo}
          disabled={prevDisabled}
          onClick={handlePrevClick}
          width={[0.4, 'auto']}
          tabIndex={0}
          variant="contained"
        >
          {prevText || 'Previous'}
        </StepsNavButton>
        <StepsNavButton
          id="showing_nav_next"
          type={nextType || 'next'}
          to={nextTo}
          onClick={handleNextClick}
          disabled={nextDisabled}
          width={[0.4, 'auto']}
          tabIndex={0}
          role="button"
          variant="contained"
        >
          {submitting ? 'Processing...' : nextText || 'Next'}
        </StepsNavButton>
      </Fragment>
    )}
  </StepsNavContainer>
);

export default withTheme(ShowingNav);
