import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
// Material UI
import Box from '@material-ui/core/Box';
import withTheme from '@material-ui/styles/withTheme';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReplayIcon from '@material-ui/icons/Replay';
import InfoIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';
import UpdateIcon from '@material-ui/icons/Update';

import ShowingCode from '../components/ShowingCode';
import { Label, Value } from './CardCommon';

const StatusListItem = withTheme(styled(ListItem)`
  ${(props) => {
    return css`
      line-height: 28px;
      cursor: ${props.status === 'failed' ? 'pointer' : 'inherit'};
      opacity: ${{
        active: 1,
        failed: 1,
        completed: 0.3,
        finished: 1,
        pending: 0.15,
      }[props.status]};
    `;
  }};
`);

const StatusListIcon = styled(ListItemIcon)`
  &&& {
    text-align: right;
    margin-left: 16px;
    min-width: 24px;
    position: relative;
  }
`;

const CodeFailedDialog = ({ onClick }) => {
  const [closed, setClosed] = useState(false);
  const contactUsUrl = process.env.REACT_APP_CUSTOMER_WEBSITE_URL + process.env.REACT_APP_CONTACT_US;

  return (
    <Dialog open={!closed}>
      <DialogContent>
        <h2>Could not generate code</h2>
        <p>
          There was an issue generating your access code, please try again. If the issue persists, please{' '}
          <a href={contactUsUrl}>contact us.</a>
        </p>
        <Box display="flex" mb={2}>
          <Box mr={2}>
            <Button color="primary" variant="outlined" size="large" onClick={() => setClosed(true)}>
              cancel
            </Button>
          </Box>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              onClick();
              setClosed(true);
            }}
          >
            Try again
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default function ShowingStatus({ showing, onRetry, processing = false, failed = false }) {
  if (!showing) {
    return null;
  }

  const propsFor = (timestamp, prevTimestamp = false) => {
    if (showing[timestamp]) {
      return { status: 'completed' };
    } else if (!processing && (failed || (showing.status === 'Failed' && (!prevTimestamp || showing[prevTimestamp])))) {
      return { status: 'failed', onClick: onRetry };
    } else if (!prevTimestamp || showing[prevTimestamp]) {
      return { status: 'active' };
    }

    return { status: 'pending' };
  };

  const propsForCode = propsFor('generated_at', 'validated_at');

  return (
    <Fragment>
      {['Pending', 'Canceled', 'Completed'].includes(showing.status) && (
        <Box width="100%" pl={1} pr={1} flex={1}>
          <Value>
            <Label>Status</Label>
            <StatusItem
              label={showing.status === 'Pending' ? 'Confirmed' : showing.status}
              status={['Pending', 'Completed'].includes(showing.status) ? 'finished' : 'pending'}
            />
            {showing.status === 'Pending' && !showing.code ? (
              <Fragment>
                <StatusItem status="info" label="Your tour code will be available a few minutes before your showing." />
                <StatusItem
                  status="info"
                  label="You will receive your tour code via e-mail and/or text message, according to your communication preferences."
                  JobStatusIcon={EmailIcon}
                />
                <StatusItem
                  status="info"
                  label="You can return to this page at the time of your tour to view your code."
                  JobStatusIcon={UpdateIcon}
                />
              </Fragment>
            ) : null}
          </Value>
        </Box>
      )}

      {['Pending', 'Completed'].includes(showing.status) && showing.code && (
        <Box width="100%" pl={1} pr={1} flex={1}>
          <ShowingCode showing_id={showing.id} code={showing.code} />
        </Box>
      )}

      {!['Pending', 'Completed', 'Canceled'].includes(showing.status) && (
        <List>
          <StatusItem
            label="Validating User Information"
            {...propsFor('validated_at')}
            successIcon={VerifiedUserIcon}
          />
          <StatusItem label="Generating Tour Code" {...propsForCode} />
          {propsForCode.status === 'failed' && <CodeFailedDialog onClick={onRetry} />}
          <StatusItem label="Sending Confirmation" {...propsFor('notified_at', 'generated_at')} />
          <StatusItem label="Saving Details" {...propsFor('tracked_at', 'notified_at')} />
        </List>
      )}
    </Fragment>
  );
}

function StatusItem({
  label,
  status,
  secondary = false,
  successIcon = CheckCircleIcon,
  JobStatusIcon = null,
  ...rest
}) {
  let jobStatusColor = 'disabled';
  let jobTypeVariant = 'body';

  if (['completed', 'finished'].includes(status)) {
    jobStatusColor = 'primary';
    jobTypeVariant = status === 'finished' ? 'h5' : 'body';
    if (!JobStatusIcon) {
      JobStatusIcon = successIcon;
    }
  } else if (status === 'active') {
    jobStatusColor = 'primary';
    if (!JobStatusIcon) {
      JobStatusIcon = CircularProgress;
    }
  } else if (status === 'failed') {
    jobStatusColor = 'error';
    if (!JobStatusIcon) {
      JobStatusIcon = ReplayIcon;
    }
  } else if (status === 'info') {
    jobStatusColor = 'info';
    jobTypeVariant = 'body2';
    if (!JobStatusIcon) {
      JobStatusIcon = InfoIcon;
    }
  }

  if (!JobStatusIcon) {
    JobStatusIcon = MoreHorizIcon;
  }

  return (
    <StatusListItem status={status} color={jobStatusColor} {...rest}>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          color: jobStatusColor,
          variant: jobTypeVariant,
        }}
        secondary={secondary || (status === 'failed' ? 'Failed. Click to retry.' : '')}
        secondaryTypographyProps={{ color: jobStatusColor }}
      />
      <StatusListIcon>
        <JobStatusIcon color={jobStatusColor} size={24} />
      </StatusListIcon>
    </StatusListItem>
  );
}
