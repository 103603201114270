.hyperlink {
  color: var(--color, var(--color-secondary));
  text-decoration: none;
  font-weight: 300;
}

.hyperlink:hover {
  color: var(--color-primary);
  cursor: pointer;
}
