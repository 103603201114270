const { lighten, darken } = require('polished');
const palette = {
  primary: {
    main: '#01833C',
    contrastText: '#fff',
  },
  secondary: {
    main: 'rgb(135, 186, 227)',
    contrastText: '#fff',
  },
  black: {
    main: '#000',
    contrastText: '#fff',
  },
  blue: {
    main: '#3CB8E5',
    contrastText: '#fff',
  },
  red: {
    main: '#d18689',
    contrastText: '#fff',
  },
  green: {
    main: '#7ED321',
    hover: '#009412',
    contrastText: '#fff',
  },
  orange: {
    main: '#F5A623',
    contrastText: '#fff',
  },
  tonalOffset: 0.2,
  background: { paper: '#fff', default: '#fafafa' },
  contrastThreshold: 3,
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A700: '#616161',
    A100: '#d5d5d5',
    A400: '#303030',
    A200: '#aaaaaa',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  ih: {
    silver_chalice: '#A2A2A2',
    alabaster: '#FAFAFA',
    mine_shaft: '#2E2E2E',
    white: '#FFFFFF',
    mercury: '#E9E9E9',
    dove_gray: '#6D6D6D',
    black_squeeze: '#E6EFF5',
    green: '#99E133',
    gray: '#4C4C4C',
    dark_green: '#009412',
  },
  common: { black: '#000', white: '#fff' },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  type: 'light',
  action: {
    hoverOpacity: 0.08,
    hover: 'rgba(0, 0, 0, 0.08)',
    selected: 'rgba(0, 0, 0, 0.14)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    active: 'rgba(0, 0, 0, 0.54)',
  },
};

['primary', 'secondary'].forEach((color) => {
  palette[color].light = lighten(0.15, palette[color].main);
  palette[color].dark = darken(0.15, palette[color].main);
});

module.exports = palette;
