export default {
  AZ: 'America/Phoenix',
  CA: 'America/Los_Angeles',
  CO: 'America/Denver',
  CT: 'America/New_York',
  DE: 'America/New_York',
  FL: 'America/New_York',
  GA: 'America/New_York',
  IL: 'America/Chicago',
  IN: 'America/New_York',
  KS: 'America/Chicago',
  MD: 'America/New_York',
  MN: 'America/Chicago',
  MO: 'America/Chicago',
  NC: 'America/New_York',
  NH: 'America/New_York',
  NJ: 'America/New_York',
  NV: 'America/Los_Angeles',
  NY: 'America/New_York',
  OH: 'America/New_York',
  PA: 'America/New_York',
  SC: 'America/New_York',
  TN: 'America/Chicago',
  TX: 'America/Chicago',
  VA: 'America/New_York',
  WA: 'America/Los_Angeles',
};
