/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export const Warning = ({ width, height, ...rest }) => {
  return (
    <svg width={width} height={height} {...rest} viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26.6667" height="26.6667" fill="black" fillOpacity="0" transform="translate(0.166504 1.83334)" />
      <path
        d="M12.5956 15.5364C12.6436 15.9684 12.9316 16.2404 13.3316 16.2404C13.7316 16.2404 14.0196 15.9684 14.0676 15.5364L14.7556 9.52038C14.8036 9.05638 14.5476 8.70438 14.1156 8.70438H12.5476C12.1156 8.70438 11.8596 9.05638 11.9076 9.52038L12.5956 15.5364ZM11.9396 18.6564V18.6884C11.9396 19.4564 12.5316 20.0484 13.3316 20.0484C14.1316 20.0484 14.7236 19.4564 14.7236 18.6884V18.6564C14.7236 17.8884 14.1316 17.2964 13.3316 17.2964C12.5316 17.2964 11.9396 17.8884 11.9396 18.6564Z"
        fill="white"
      />
      <path
        d="M15.3401 2.77084C14.5222 1.35417 12.4774 1.35418 11.6595 2.77084L1.84458 19.7708C1.02666 21.1875 2.04906 22.9583 3.68488 22.9583H23.3148C24.9506 22.9583 25.973 21.1875 25.1551 19.7708L15.3401 2.77084Z"
        stroke="white"
        strokeWidth="2.25"
      />
    </svg>
  );
};

Warning.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Warning.defaultProps = {
  width: '24px',
  height: '24px',
};
