import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';

import { Flex } from '@invitation-homes/styled-ui/lib/components/Common/Containers';
import TextLoader from '@invitation-homes/styled-ui/lib/components/Loader/TextLoader';
import Done from '@material-ui/icons/Done';

import { Label, Value } from './CardCommon';

const CodeBox = styled.div`
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: #8383832b;
  border-radius: 6px;
  border-image: initial;
  padding: 6px 15px;
  box-shadow: 3px 3px 6px #949494;
  background-color: white;
  width: 95%;
`;

export default class ShowingCode extends Component {
  constructor(props) {
    super(props);
    this.state = { code: props.code };
  }
  componentDidMount() {
    if (!this.props.code) {
      this.pollingTimer = setInterval(() => {
        axios
          .get(`/api/v1/code/${this.props.showing_id}`)
          .then((response) => response.data)
          .then((data) => {
            this.setState({ code: data.code });
          });
      }, 1000 * 10);
    }
  }
  componentWillUnmount() {
    if (this.pollingTimer) {
      clearInterval(this.pollingTimer);
    }
  }
  getStatus = (code) => {
    let status;
    switch (code) {
      case null:
        status = 'pending';
        break;
      case false:
        status = 'error';
        break;
      default:
        status = 'success';
    }
    return status;
  };
  renderStatus(status) {
    let msg;
    switch (status) {
      case 'error':
        msg = 'Error';
        break;
      case 'pending':
        msg = 'Generating';
        break;
      default:
    }
    return (
      <span>
        {msg}
        <TextLoader interval={500} />
      </span>
    );
  }
  render() {
    const { code } = this.state;
    const status = this.getStatus(code);
    return (
      <Box status={status} p={0}>
        <Flex justify="space-between">
          <Box pl={1}>
            <Value>
              <Label>Access Code</Label>
              <CodeBox>
                <div style={{ fontWeight: '500', color: '#01833C' }}>{code ? code : this.renderStatus(status)}</div>
                <div
                  style={{
                    fontSize: '14px',
                    fontStyle: 'italic',
                    color: '#01833C',
                    display: 'flex',
                  }}
                >
                  {code && (
                    <React.Fragment>
                      Followed by * or{' '}
                      <div
                        style={{
                          marginLeft: '6px',
                        }}
                      >
                        <Done fontSize="small" />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </CodeBox>
            </Value>
          </Box>
        </Flex>
      </Box>
    );
  }
}
