import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { space, bg, minHeight, minWidth, display, shadow, width, withTheme } from '../../util/styled';

export const Box = withTheme(styled.div`
  position: relative;
  cursor: default;
  text-align: ${(props) => props.align || 'left'};
  ${bg};
  ${space};
  ${minHeight};
  ${minWidth};
  ${width};
  ${display};
  ${shadow};
`);
Box.displayName = 'Box';

export const Flex = withTheme(styled.div`
  display: flex;
  height: ${(props) => props.height || 'auto'};
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  flex-wrap: ${(props) => (props.wrap === 'true' ? 'wrap' : 'nowrap')};
  ${space};
`);
Flex.displayName = 'Flex';
Flex.propTypes = {
  wrap: PropTypes.string,
};
Flex.defaultProps = {
  wrap: 'false',
};

export const Row = styled.div`
  ${space};
  ${minHeight};
`;
Row.displayName = 'Row';

export const Container = styled.div`
  -webkit-font-smoothing: subpixel-antialiased;
  padding: 10px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  ${space};
`;
Container.displayName = 'Container';

export const Section = styled.div`
  background-color: #fff;
  padding: 20px;
  ${space};
`;
Section.displayName = 'Section';

export const FlexSection = styled(Section)`
  display: flex;
  justify-content: center;
  ${space};
`;
FlexSection.displayName = 'FlexSection';

/**
 * Returns a grid item for react child
 * @param  { breakpoint: number }[] Spacing Value -
 * Array of objects that define the spacing for each child value
 * e.g. [ {xl : 12} ] SEE: https://material-ui.com/api/grid/
 * @param {spacing} - Defines the spacing in between each child.
 * Defaults to 16. See the material-ui grid api
 */
export const GridContainer = withTheme(({ spacing = 16, childSpacing = [], children, ...rest }) => {
  return (
    <Grid container spacing={spacing} {...rest}>
      {React.Children.map(children, (child, i) => {
        return (
          <Grid item {...childSpacing[i]}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
});

GridContainer.displayName = 'GridContainer';
