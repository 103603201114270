import React from 'react';
import styled from 'styled-components';
// Styled UI
import Warning from '@invitation-homes/styled-ui/lib/components/Symbols/Warning';
import AspectRatio from '@invitation-homes/styled-ui/lib/components/Common/AspectRatio';
// Material UI
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import withTheme from '@material-ui/styles/withTheme';

import { transparentize } from 'polished';

export const Card = withTheme(styled.div`
  color: ${(props) => {
    if (props.submitted) return props.theme.palette.green.main;
    if (props.error) return props.theme.palette.error.dark;
    if (props.unavailable) return props.theme.palette.grey[500];
  }};
  padding: ${(props) => props.padding && props.padding};
`);

export const CardRedText = withTheme(styled.div`
  color: ${(props) => props.theme.palette.error.dark};
`);

export const Address = withTheme(styled.h3`
  color: #fff;
  text-align: left;
  font-size: ${(props) => props.fontSize || props.theme.typography.fontSize};
  line-height: 1.3;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-font-smoothing: antialiased;
  z-index: 1;
  margin: 0;
  padding: 10px;
`);

export const CardContainer = styled.div`
  display: flex;
  width: ${(props) => props.width}
    ${(props) =>
      props.disabled
        ? `
  cursor: not-allowed
  `
        : ''};
  flex-direction: row;
  background: #fafafa;
  border: 1.1px solid rgba(151, 151, 151, 0.21);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.03);

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export const CardDetails = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardImageContainer = styled(AspectRatio)`
  position: relative;
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  ${(props) => (props.flex ? `flex: ${props.flex}` : '')}
  @media (max-width: 850px) {
    width: 100%;
  }
  @media (max-width: 850px) {
    position: relative;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 31%, rgba(0, 0, 0, 0.54) 87%);
    /*box-shadow: 0 3px 14px 0 rgba(0,0,0,0.13);*/
  }
`;

export const CardLabel = withTheme(styled.p`
  font-size: ${(props) => props.theme.typography.fontSize};
  color: ${transparentize(0.29, '#000')};
  margin: 0 0 2px 0;
  -webkit-font-smoothing: antialiased;
  user-select: none;
`);

export const CardValue = withTheme(styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.typography.headline.fontSize};
  color: ${transparentize(0.29, '#000')};
  margin: 0 0 15px 0;
  -webkit-font-smoothing: antialiased;

  @media (max-width: 920px) {
    font-size: ${(props) => props.theme.typography.fontSize};
  }
`);
const CornerRibbonTriangle = styled.div`
  position: absolute;
  border-width: 80px 80px 0 0;
  border-color: #ffc530 transparent transparent transparent;
  border-style: solid;
  top: 0px;
  left: 0px;
  filter: drop-shadow(2px 0px 8px rgba(0, 0, 0, 0.25));
`;

export const Image = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;

  @media (max-width: 960px) {
    position: unset;
    height: 280px;
    ${(props) => (props.mobile ? `position: unset; height: 280px` : '')}
  }
`;
export const CardHeader = withTheme(styled.h3`
  color: ${(props) => props.color || transparentize(0.29, '#000')};
  text-align: ${(props) => props.centered && 'center'};
  margin: ${(props) => props.margin && props.margin}
  font-size: ${(props) => props.fontSize && props.fontSize}
`);

export const CornerRibbon = () => (
  <CornerRibbonTriangle>
    <Warning width="35px" height="35px" style={{ position: 'absolute', left: '6px', top: '-73px' }} />
  </CornerRibbonTriangle>
);
export const CardImage = ({ item, fontSize }) => {
  const { photos = [] } = item.unit || {};
  return (
    <Image image={encodeURI(photos.length !== 0 && photos[0].image_url)}>
      <Address fontSize={fontSize}>
        {item.unit.address_1}
        <br />
        {`${item.unit.city}, ${item.unit.state} ${item.unit.zipcode}`}
      </Address>
    </Image>
  );
};

export const Description = withTheme(styled.p`
  color: rgb(102, 102, 102);
  margin-bottom: 20px;
  font-size: 16px;
  padding: 0;
  line-height: 1.625em;
`);

export const Checkmark = (props) => (
  <svg
    onClick={props.onClick}
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.81388 10.4427L18.5818 0.6742C19.4808 -0.224829 20.9387 -0.22483 21.8377 0.674775C22.7362 1.57438 22.7362 3.03228 21.8366 3.93016L10.441 15.3257C9.54197 16.2248 8.08407 16.2248 7.18446 15.3257L0.674272 8.81439C-0.224757 7.91593 -0.224757 6.45803 0.674272 5.559C1.5733 4.65997 3.0312 4.65997 3.93023 5.559L8.81388 10.4427Z"
      fill="#3A9AE9"
    />
  </svg>
);

export const Header = withTheme(styled.h2`
  color: rgb(61, 155, 233);
  margin-bottom: 20px;
  padding: 0;
  line-height: 1.625em;
`);

export const Column = withTheme(styled(Box)`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : transparentize(0.29, props.theme.palette.grey_light)};
  text-align: ${(props) => props.centered && 'center'};
  @media (max-width: 850px) {
    width: 100%;
  }
`);

export const Label = styled.div`
  font-size: 1rem;
  margin: ${(props) => props.margin || `0px;`};
  -webkit-font-smoothing: antialiased;
  user-select: none;
  font-weight: lighter;
  color: ${({ color = '#00000085' }) => color};

  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const Value = styled.div`
  ${(props) => (props.width ? `width: ${props.width}` : '')}
  ${(props) => (props.padding ? `padding: ${props.padding}` : '')}
  ${(props) =>
    props.centered
      ? `display: flex;
  flex-direction: column;
  align-items: center;`
      : ''}
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.2rem')};
  margin: ${(props) => props.margin || `0 0 15px 0`};
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  color: #000000c9;

  @media (max-width: 1250px) {
    font-size: 1rem;
  }

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }

  @media (max-width: 960px) {
    width: unset;
    margin: ${(props) => props.margin || `8px 0`};
  }
`;

export const Info = styled.div`
  font-size: 16px;
  color: #808080;
  -webkit-font-smoothing: antialiased;
  margin-top: 8px;

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;

export const StyledGrid = styled(Grid)`
  box-sizing: border-box;
  margin: 0;
  flex-grow: 0;
  max-width: 20%;
  flex-basis: 20%;
  margin: 10px;
  background-color: white;

  @media (max-width: 730px) {
    max-width: 45%;
    flex-basis: 45%;
  }
  @media (max-width: 570px) {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const Disabled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: opacity 175ms ease-in-out;
  opacity: 1;
  cursor: not-allowed;

  span {
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
  }
`;
Disabled.displayName = 'Disabled';

export const Heading = withTheme(styled.h3`
  width: 80%;
  padding: 0;
  font-size: ${(props) => props.theme.typography.pxToRem(19)};
  color: ${(props) => props.theme.palette.ih.silver_chalice};
  -webkit-font-smoothing: antialiased;
  color: inherit;
  font-weight: normal;

  @media (max-width: 980px) {
    font-size: ${(props) => props.theme.typography.pxToRem(17)};
  }
`);

export const PageHeading = withTheme(styled(Heading)`
  font-size: ${(props) => props.theme.typography.pxToRem(25)};

  @media (max-width: 1500px) {
    font-size: ${(props) => props.theme.typography.pxToRem(20)};
  }
`);

export const PageSubHeading = withTheme(styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
`);

export const MessageContainer = withTheme(styled.div`
  background-color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`);

export const MessageHeader = styled.h2`
  font-weight: bold;
  text-align: center;
`;

export const MessageDescription = styled.div`
  font-weight: lighter;
  color: #00000085;
  max-width: 510px;
  text-align: center;
`;
