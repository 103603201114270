import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import { space } from 'styled-system';
import styled from 'styled-components';
import axios from 'axios';
import numeral from 'numeral';
import moment from 'moment-timezone';
import { updateProfileLink } from '../utils/profileLinks';
import { trackGTMEvent } from '../utils/gtm';

// Styled UI
import CircleLoader from '@invitation-homes/styled-ui/lib/components/Loader/CircleLoader';
import { Flex } from '@invitation-homes/styled-ui/lib/components/Common/Containers';
import { withServices } from '@invitation-homes/styled-ui/lib/providers/Services';

// Material UI
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import withTheme from '@material-ui/styles/withTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import formatNumber from '../utils/formatNumber';

import { MessageContainer, MessageHeader, MessageDescription, Label, Value, Info } from '../components/CardCommon';

// components
import UnitImageLarge from '../components/UnitImageLarge';
import ShowingActions from '../components/ShowingActions';

// constants
import timezones from '../../constants/timezones';
import SelfTourHeader from '../components/SelfTourHeader';

const ImageContainer = styled.div`
  position: relative;
  ${space};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  @media (max-width: 960px) {
    height: 500px;
  }

  @media (max-width: 430px) {
    height: 435px;
  }
`;

const PositionedShowingActions = styled(ShowingActions)``;

const Container = styled.div`
  ${space};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #bcbcbc;
  padding: 16px;
  padding-top: 40px;
  height: 100%;
  align-items: ${(props) => props.alignItems || 'center'};

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
  @media (max-width: 430px) {
    padding-top: 24px;
  }
`;

const ContentContainer = styled(Flex)`
  background-color: white;
  width: 85%;
  padding: 60px;
  margin-bottom: 35px;
  align-items: stretch;
  flex-wrap: wrap;
  max-width: 1800px;
  box-sizing: border-box;

  @media (max-width: 2000px) {
    box-sizing: content-box;
  }

  @media (max-width: 1250px) {
    padding: 15px;
    width: 100%;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    width: 525px;
    padding: 20px 45px;
  }

  @media (max-width: 525px) {
    width: 100%;
  }
`;

// background-color: #f6f5f6;
const Content = styled(Box)`
  flex: ${(props) => props.flex || '1'};
  margin: 10px;
  min-width: 260px;
`;

const StyledBox = styled(Box)`
  flex: 2;
  justify-content: space-evenly;

  @media (max-width: 960px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const CardContainer = styled.div`
  margin: 15px 0px;
  justify-content: center;
  background-color: white;
  width: 100%;
  display: flex;
  height: ${(props) => props.height || '300px'};
  padding: 50px;
  box-sizing: border-box;

  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;

const FraudMsgContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.5em;

  @media (max-width: 960px) {
    width: 90%;
  }
`;

const TextLink = withTheme(styled.a`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: bold;
  text-decoration: unset;
`);

const ShowingLabel = styled(Label)`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: #808080;

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;
const ShowingLabelSuffix = styled.span`
  font-size: 16px;
  color: #808080;

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;
const ShowingValue = styled(Value)`
  font-size: 24px;
  line-height: 1.2;
  color: #191919;

  @media (max-width: 960px) {
    font-size: 16px;
  }
`;
const ShowingAddress = styled(Value)`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  color: #191919;

  @media (max-width: 960px) {
    font-size: 18px;
  }
`;
export default withRouter(
  withServices(['dataLayer', 'storage'])(
    class Showing extends Component {
      constructor(props) {
        super(props);
        this.state = {
          loading: true,
          inPast: true,
          cancelShowingModal: false,
          timezone: null,
        };
      }
      componentDidMount() {
        axios
          .get(`/api/v1/showing/${this.props.match.params.id}`)
          .then((response) => response.data)
          .then((data) => {
            if (data.canceled_at) {
              this.props.history.push('/homes');
            } else {
              const timezone = timezones[data.unit.state];
              this.setState({
                data,
                timezone,
                loading: false,
                inPast: moment(data.start_time).tz(timezone).isBefore(moment().tz(timezone)),
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
              error: err,
            });
          });
      }
      onEdit = () => {
        this.props.history.push(`${this.props.match.url}/edit`);
      };
      onCancel = () => {
        this.setState({ loading: true });
        axios
          .put(`/api/v1/showing/${this.props.match.params.id}`, {
            canceled_at: moment.utc().format(),
          })
          .then(() => {
            trackGTMEvent({
              application: 'self-show',
              event: 'SS_Cancel_Showing',
            });
            this.setState({
              canceled: true,
            });
          });
      };
      render() {
        const { data, timezone } = this.state;
        const unit = get(data, 'unit');

        if (this.state.canceled) {
          return (
            <Fragment>
              <Redirect to="/homes" />
            </Fragment>
          );
        } else if (this.state.error) {
          return (
            <Flex direction="column" wrap="true" m={-10} pt={50} pb={50} pl={30} pr={30}>
              <CardContainer>
                <MessageContainer>
                  <MessageHeader>Oops! Something went wrong</MessageHeader>
                  <MessageDescription>
                    The Self-Tour information you're looking for is stuck momentarily.
                  </MessageDescription>
                  <MessageDescription>
                    You could <TextLink href="/">Try Again</TextLink> or head back to your{' '}
                    <TextLink href={updateProfileLink()}>Profile</TextLink>.
                  </MessageDescription>
                  <Box mt={2}>
                    <Button color="primary" variant="contained" href={process.env.REACT_APP_PLS_URL} fontSize="10px">
                      Search Our Homes
                    </Button>
                  </Box>
                </MessageContainer>
              </CardContainer>
            </Flex>
          );
        } else if (this.state.loading) {
          return (
            <Container p={2}>
              <CircleLoader size={24} />
            </Container>
          );
        }

        const photo = encodeURI(unit.photos.length !== 0 && unit.photos[0].image_url);

        return (
          <Flex>
            {this.state.cancelShowingModal && (
              <Dialog
                open={this.state.cancelShowingModal}
                onClose={() => this.setState({ cancelShowingModal: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Cancel Showing</DialogTitle>
                <DialogContent style={{ padding: '0 24px 15px' }} t>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to cancel your self showing?
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', margin: '5px 4px 20px' }}>
                  <Button fullWidth={true} color="primary" variant="contained" onClick={() => this.onCancel()}>
                    Yes, Cancel
                  </Button>
                  <Button
                    fullWidth={true}
                    color="primary"
                    variant="outlined"
                    onClick={() => this.setState({ cancelShowingModal: false })}
                  >
                    No
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            <SelfTourHeader width={'92%'} />
            <ContentContainer direction="row" wrap="true" justify="space-between">
              <Content id="Showing-details">
                <PositionedShowingActions
                  onCancel={() => {
                    this.setState({ cancelShowingModal: true });
                  }}
                  inPast={this.state.inPast}
                  onEdit={this.onEdit}
                  date={get(data, 'date')}
                  time={get(data, 'start_time')}
                  timezone={timezone}
                  data={data}
                />
              </Content>
              <Content flex="3" id="property-details">
                <ImageContainer>
                  <UnitImageLarge image={photo} />
                  <Container alignItems="flex-start">
                    <ShowingAddress>
                      {get(unit, 'address_1')}
                      <br />
                      {get(unit, 'city')}, {get(unit, 'state')} {get(unit, 'zipcode')}
                    </ShowingAddress>
                    <StyledBox direction="row" display="flex">
                      <ShowingValue centered>
                        <ShowingLabel margin="0 0 8px 0">Lease For</ShowingLabel>
                        <div>
                          {`${numeral(get(unit, 'market_rent')).format('$0,0')}`}
                          <ShowingLabelSuffix>/mo</ShowingLabelSuffix>
                        </div>
                        <Info>* plus required fees</Info>
                      </ShowingValue>
                      <ShowingValue centered>
                        <ShowingLabel margin="0 0 8px 0">Bed</ShowingLabel>
                        {`${get(unit, 'beds')} br`}
                      </ShowingValue>
                      <ShowingValue centered>
                        <ShowingLabel margin="0 0 8px 0">Bath</ShowingLabel>
                        {`${formatNumber.truncate(get(unit, 'baths'))} ba`}
                      </ShowingValue>
                      <ShowingValue centered>
                        <ShowingLabel margin="0 0 8px 0">Size</ShowingLabel>
                        {`${numeral(get(unit, 'sqft')).format('0,0')} sqft`}
                      </ShowingValue>
                    </StyledBox>
                  </Container>
                </ImageContainer>
              </Content>
              <Flex>
                <FraudMsgContainer>
                  <Value fontSize="0.8em">
                    Please note, the code is only active for a limited time. You will receive an e-mail from
                    no-reply@invitationhomes.com or a text message from 866-535-8696. If you receive a code from a third
                    party, please be aware that it may be a fraudulent use of the code.
                  </Value>
                  <Value fontSize="0.8em">
                    The unauthorized use and/or distribution of this code is illegal and can result in severe criminal
                    and civil penalties.
                  </Value>
                </FraudMsgContainer>
              </Flex>
            </ContentContainer>
          </Flex>
        );
      }
    },
  ),
);
