.profile-menu {
  display: flex;
  flex-direction: column;
  padding: 24px;
  right: 0;
  z-index: var(--z-index-profile-drawer-top);
}

.avatar {
  border: none;
  background: none;
  color: var(--color-secondary);
  padding: 0px;
  height: 24px;
  width: 24px;
}
.avatar:hover,
.avatar:active,
.avatar:focus {
  cursor: pointer;
}
.avatarOn {
  color: var(--color-primary);
}
