.headerSubmenu {
  z-index: var(--headerSubmenu-z-index, 20);
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  position: absolute;
  bottom: -82px;
  left: 0;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-grey-100);
  border-bottom: 1px solid var(--color-grey-100);
  overflow-y: hidden;
  width: 100%;
}

.headerSubmenu ul {
  display: flex;
  padding: 0;
}

.headerSubmenu li {
  list-style: none;
  font-size: 16px;
}

@media (min-width: 1160px) {
  .headerSubmenu li {
    padding: 32px 24px;
  }

  .headerSubmenu {
    transition: opacity 0.5s;
    opacity: 0;
    pointer-events: none;
  }

  .headerSubmenu.visible,
  .headerSubmenu:focus-within {
    opacity: 1;
    pointer-events: auto;
  }

  .headerSubmenu:focus-within {
    color: var(--color-primary);
  }
}
