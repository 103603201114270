/* eslint-disable complexity */
import React from 'react';
import * as styles from './ProfileMenuItem.module.scss';
import Link from './ProfileMenuItemLink';

const ProfileMenuItem = ({ label, url = '', variant = 'link', hasSeparator = false }) => {
  return (
    <>
      {hasSeparator && <hr className={`${styles.separator}`} />}
      {label && (
        <>
          {variant === 'heading' && (
            <div className={`${styles.heading}`}>
              <div className={`${styles.headingLabel}`}>{label}</div>
            </div>
          )}
          {variant === 'link' && (
            <div className={`${styles.link}`}>
              <Link url={url}>{label}</Link>
            </div>
          )}
          {variant === 'padded-link' && (
            <div className={`${styles['padded-link']}`}>
              <Link url={url}>{label}</Link>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProfileMenuItem;
