const HindFontFamily = ['Hind', 'Helvetica', 'Arial', 'sans-serif'].map((font) => `"${font}"`).join(', ');

const headlineElementStyles = {
  fontFamily: HindFontFamily,
};

module.exports = {
  useNextVariants: true,
  fontFamily: ['Hind', '-apple-system', 'BlinkMacSystemFont', 'Helvetica', 'Arial', 'sans-serif'],
  button: {
    fontFamily: HindFontFamily,
    fontWeight: 500,
    letterSpacing: '1.25px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  h1: headlineElementStyles,
  h2: headlineElementStyles,
  h3: headlineElementStyles,
  h4: headlineElementStyles,
  h5: headlineElementStyles,
  h6: headlineElementStyles,
};
