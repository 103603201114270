const axios = require('axios');

export default class PLSClient {
  state = { markets: false };

  promises = { markets: false };

  constructor(options = {}) {
    if (!options.endpoint) {
      throw new Error('PLS-Client: options.endpoint is required');
    }

    this.endpoint = options.endpoint;
    this.connector = options.connector || axios.create();
  }

  getUnit(unitData) {
    return this.connector.get(`${this.endpoint}/property-by`, { params: unitData }).then((response) => response.data);
  }

  getUnits(unitData) {
    return this.connector.post(`${this.endpoint}/properties-by`, unitData).then((response) => response.data);
  }
}
