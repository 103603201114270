import mitt from 'mitt';
// Event emitter
const emitter = new mitt();
window.emitter = emitter;
module.exports = emitter;

emitter.on('network-error', () => {
  emitter.emit('notification', {
    type: 'error',
    message: 'We’re sorry, an error occurred on our end. Please try back again later.',
  });
});
