.link {
  display: block;
  font-family: var(--font-primary), sans-serif;
  text-decoration: none;
  color: var(--color-white);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  user-select: none;
  -webkit-font-smoothing: antialiased;
}

.padded {
  padding-left: 8px;
}

@media (min-width: 1160px) {
  .link {
    color: var(--color-secondary);
    transition: color 0.2s;
  }

  .link:hover,
  .link:active,
  .link:focus {
    color: var(--color-primary);
  }
}
