.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: var(--z-index-profile-drawer);
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s;
}

.backdrop.visible {
  visibility: visible;
  opacity: 1;
}

.drawer {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: calc(100% - 64px);
  height: 100%;
  top: 0;
  overflow-y: scroll;
  z-index: var(--z-index-profile-drawer-top);
}

.drawer.white {
  background: var(--color-white);
}

.drawer.primary-dark {
  background: var(--color-primary-dark);
}

.drawer.left {
  left: 0;
  transform: translateX(-100%);
  transition: all 0.45s;
}

.drawer.left.slideIn {
  transform: translateX(0%);
}

.drawer.right {
  right: 0;
  transform: translateX(100%);
  transition: all 0.45s;
}

.drawer.right.slideIn {
  transform: translateX(0%);
}

.drawer::-webkit-scrollbar {
  display: none;
}

.close {
  border: none;
  position: fixed;
  background-color: transparent;
  top: 16px;
  color: var(--color-white);
  z-index: var(--z-index-profile-drawer-top);
  height: 32px;
  width: 32px;
  padding: 4.5px;
  cursor: pointer;
}

.close.left {
  transition: all 0.45s;
  transform: translateX(-100%);
}
.close.left.slideIn {
  transform: translateX(calc(100vw - 48px));
}

.close.right {
  right: 0;
  transition: all 0.45s;
  transform: translateX(100%);
}
.close.right.slideIn {
  transform: translateX(calc(-100vw + 48px));
}

@media (min-width: 768px) {
  .drawer {
    width: 50%;
  }

  .close.right {
    transform: translateX(100%);
  }
  .close.right.slideIn {
    transform: translateX(calc(-50vw - 16px));
  }

  .close.left {
    transform: translateX(-100%);
  }

  .close.left.slideIn {
    transform: translateX(calc(50vw + 16px));
  }
}
