import React, { Fragment } from 'react';
import { ContactUsLink, PlaidIdentityVerificationError } from './PlaidIdentityVerificationError';

const InitializationError = () => {
  return (
    <PlaidIdentityVerificationError>
      <Fragment>
        We are sorry we were unable to process your request. Please try again, if you continue to experience an issue,
        please <ContactUsLink />.
      </Fragment>
    </PlaidIdentityVerificationError>
  );
};

export default InitializationError;
