.separator {
  width: 100%;
  border: solid 1px var(--color-grey-200);
  border-bottom-width: 0px;
  margin: 16px 0px;
}

.link,
.padded-link {
  height: 20px;
}

.heading,
.heading:first-of-type + .padded-link {
  margin-top: 16px;
}

.link {
  margin-top: 4px;
}

.link:first-of-type,
.heading:first-of-type,
.separator + .link,
.separator + .heading {
  margin-top: 0px;
}

.padded-link {
  margin-top: 12px;
  margin-left: 8px;
}

.heading + .padded-link {
  margin-top: 12px;
}

.headingLabel {
  font-family: var(--font-primary), sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  color: var(--color-secondary-light);
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 100%;
}

@media (max-width: 1159px) {
  .separator {
    border-color: var(--color-grey-200);
  }
  .headingLabel {
    color: var(--color-white);
    display: block;
  }
}
