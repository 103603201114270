import { get } from 'lodash';

export default function captureUnitId(props) {
  let unitId = null;
  try {
    // Make sure we keep unitId to persist on every occation of redirects. This should solve the issue with not being able to rely on it sometimes during page refreshes.
    const parsedUnitId = parseInt(get(props.match, 'params.unit_id', NaN), 10);
    unitId = isNaN(parsedUnitId) ? null : parsedUnitId;
    const currentlyStoredUnitId = localStorage.getItem('unit_id');
    if (unitId) {
      localStorage.setItem('unit_id', unitId);
    } else if (currentlyStoredUnitId) {
      unitId = currentlyStoredUnitId;
    }
  } catch (error) {
    // Take that, error!
  }
  return unitId;
}
