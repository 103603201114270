/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const logoRatio = 266 / 70;

const LogoH1 = styled.div`
  justify-content: left;
  align-items: center;
  margin: 0;

  svg {
    display: block;
    margin: auto;
  }
`;

const LogoIH = ({ width, ...next }) => {
  const height = width / logoRatio - 15;

  return (
    <LogoH1 {...next}>
      <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} viewBox="0 0 517.21 100.89">
        <g>
          <path
            fill="#9FCC3B"
            d="M86.54,50.45l9.47-9.47c1.98-1.98,1.98-5.2,0-7.18L67.89,5.68c-1.98-1.98-5.2-1.98-7.18,0l-9.47,9.47
		l-9.47-9.47c-1.98-1.98-5.2-1.98-7.18,0L6.48,33.8c-1.98,1.98-1.98,5.2,0,7.18l9.47,9.47l-9.47,9.47c-1.98,1.98-1.98,5.2,0,7.18
		L34.6,95.21c1.98,1.98,5.2,1.98,7.18,0l9.47-9.47l9.47,9.47c1.98,1.98,5.2,1.98,7.18,0l28.12-28.12c1.98-1.98,1.98-5.2,0-7.18
		L86.54,50.45z"
          />
          <path
            fill="#04A54F"
            d="M40.52,75.02c-1.25-1.25-1.76-3.06-1.35-4.77l2.45-10.18l-10.18,2.45c-1.72,0.41-3.53-0.1-4.77-1.35
		L15.94,50.45l-9.47,9.47c-1.98,1.98-1.98,5.2,0,7.18L34.6,95.21c1.98,1.98,5.2,1.98,7.18,0l9.47-9.47L40.52,75.02z"
          />
          <path
            fill="#0C9347"
            d="M57.86,47.58c-0.45-1.85-1.89-3.3-3.75-3.75l-22.67-5.45c-1.72-0.41-3.53,0.1-4.78,1.35L15.94,50.44
		l10.72,10.72c1.25,1.25,3.06,1.76,4.78,1.35l22.67-5.45c1.85-0.45,3.3-1.89,3.75-3.75l0.69-2.87L57.86,47.58z"
          />
          <path
            fill="#0C9347"
            d="M48.37,43.83c-1.85,0.45-3.3,1.89-3.75,3.75l-5.45,22.67c-0.41,1.72,0.1,3.53,1.35,4.78l10.72,10.72
		l10.72-10.72c1.25-1.25,1.76-3.06,1.35-4.78l-5.45-22.67c-0.45-1.85-1.89-3.3-3.75-3.75l-2.87-0.69L48.37,43.83z"
          />
          <path
            fill="#65BC46"
            d="M44.62,53.32c0.45,1.85,1.89,3.3,3.75,3.75l22.67,5.45c1.72,0.41,3.53-0.1,4.78-1.35l10.72-10.72L75.82,39.72
		c-1.25-1.25-3.06-1.76-4.78-1.35l-22.67,5.45c-1.85,0.45-3.3,1.89-3.75,3.75l-0.69,2.87L44.62,53.32z"
          />
          <path
            fill="#65BC46"
            d="M54.11,57.06c1.85-0.45,3.3-1.89,3.75-3.75l5.45-22.67c0.41-1.72-0.1-3.53-1.35-4.78L51.24,15.15L40.52,25.87
		c-1.25,1.25-1.76,3.06-1.35,4.78l5.45,22.67c0.45,1.85,1.89,3.3,3.75,3.75l2.87,0.69L54.11,57.06z"
          />
          <path
            fill="#3CAE49"
            d="M44.62,53.32c0.45,1.85,1.89,3.3,3.75,3.75l2.87,0.69l2.87-0.69c1.85-0.45,3.3-1.89,3.75-3.75l3-12.49
		l-12.49,3c-1.85,0.45-3.3,1.89-3.75,3.75l-0.69,2.87L44.62,53.32z"
          />
          <path
            fill="#168241"
            d="M54.11,57.06c1.85-0.45,3.3-1.89,3.75-3.75l0.69-2.87l-0.69-2.87c-0.45-1.85-1.89-3.3-3.75-3.75l-12.49-3
		l3,12.49c0.45,1.85,1.89,3.3,3.75,3.75l2.87,0.69L54.11,57.06z"
          />
          <path
            fill="#168241"
            d="M48.37,43.83c-1.85,0.45-3.3,1.89-3.75,3.75l-0.69,2.87l0.69,2.87c0.45,1.85,1.89,3.3,3.75,3.75l12.49,3
		l-3-12.49c-0.45-1.85-1.89-3.3-3.75-3.75l-2.87-0.69L48.37,43.83z"
          />
          <path
            fill="#168241"
            d="M57.86,47.58c-0.45-1.85-1.89-3.3-3.75-3.75l-2.87-0.69l-2.87,0.69c-1.85,0.45-3.3,1.89-3.75,3.75l-3,12.49
		l12.49-3c1.85-0.45,3.3-1.89,3.75-3.75l0.69-2.87L57.86,47.58z"
          />
          <path
            fill="#0B763C"
            d="M57.86,47.58c-0.45-1.85-1.89-3.3-3.75-3.75l-2.87-0.69l-2.87,0.69c-1.85,0.45-3.3,1.89-3.75,3.75l-0.69,2.87
		l0.69,2.87c0.45,1.85,1.89,3.3,3.75,3.75l2.87,0.69l2.87-0.69c1.86-0.45,3.3-1.89,3.75-3.75l0.69-2.87L57.86,47.58z"
          />
          <polygon
            fill="#65BC46"
            points="507.04,40.81 506.02,40.81 506.02,40.29 508.63,40.29 508.63,40.81 507.61,40.81 507.61,43.51 
		507.04,43.51 	"
          />
          <polygon
            fill="#65BC46"
            points="509.06,40.29 509.66,40.29 510.64,41.81 511.62,40.29 512.22,40.29 512.22,43.51 511.66,43.51 
		511.66,41.2 510.64,42.72 510.62,42.72 509.62,41.21 509.62,43.51 509.06,43.51 	"
          />
          <path
            fill="#65BC46"
            d="M126.43,32.7c0-1.82,1.52-2.98,3.48-2.98c1.97,0,3.48,1.16,3.48,2.98V33c0,1.82-1.51,3.03-3.48,3.03
		c-1.97,0-3.48-1.21-3.48-3.03V32.7z"
          />
          <path
            fill="#231F20"
            d="M126.88,42.65c0-1.72,1.31-3.08,3.03-3.08c1.72,0,3.08,1.36,3.08,3.08v21.12c0,1.72-1.36,3.03-3.08,3.03
		c-1.67,0-3.03-1.31-3.03-3.03V42.65z"
          />
          <path
            fill="#231F20"
            d="M139.01,42.65c0-1.72,1.31-3.08,3.03-3.08c1.72,0,3.08,1.36,3.08,3.08v1.31c1.72-2.48,4.19-4.65,8.33-4.65
		c6.01,0,9.5,4.04,9.5,10.2v14.25c0,1.72-1.31,3.03-3.03,3.03c-1.72,0-3.08-1.31-3.08-3.03V51.39c0-4.14-2.07-6.52-5.71-6.52
		c-3.54,0-6.01,2.48-6.01,6.62v12.27c0,1.72-1.36,3.03-3.08,3.03c-1.67,0-3.03-1.31-3.03-3.03V42.65z"
          />
          <path
            fill="#231F20"
            d="M178.53,67.05h-0.3c-1.67,0-2.78-1.06-3.49-2.68l-8.89-20.36c-0.15-0.45-0.35-0.96-0.35-1.52
		c0-1.52,1.36-2.93,3.03-2.93c1.67,0,2.53,0.96,3.03,2.22l6.87,17.68l6.97-17.78c0.46-1.06,1.26-2.12,2.88-2.12
		c1.67,0,2.98,1.26,2.98,2.93c0,0.56-0.2,1.16-0.35,1.46l-8.89,20.41C181.31,65.94,180.2,67.05,178.53,67.05"
          />
          <path
            fill="#65BC46"
            d="M194.24,32.7c0-1.82,1.51-2.98,3.48-2.98c1.97,0,3.48,1.16,3.48,2.98V33c0,1.82-1.52,3.03-3.48,3.03
		c-1.97,0-3.48-1.21-3.48-3.03V32.7z"
          />
          <path
            fill="#231F20"
            d="M194.7,42.65c0-1.72,1.31-3.08,3.03-3.08c1.72,0,3.08,1.36,3.08,3.08v21.12c0,1.72-1.36,3.03-3.08,3.03
		c-1.67,0-3.03-1.31-3.03-3.03V42.65z"
          />
          <path
            fill="#231F20"
            d="M207.81,59.17V45.12h-1.01c-1.46,0-2.63-1.16-2.63-2.63c0-1.46,1.16-2.63,2.63-2.63h1.01v-4.55
		c0-1.67,1.36-3.03,3.08-3.03c1.67,0,3.03,1.36,3.03,3.03v4.55h4.8c1.46,0,2.68,1.16,2.68,2.63c0,1.47-1.21,2.63-2.68,2.63h-4.8
		v13.08c0,2.37,1.21,3.33,3.28,3.33c0.71,0,1.31-0.15,1.52-0.15c1.36,0,2.57,1.11,2.57,2.53c0,1.11-0.76,2.02-1.62,2.37
		c-1.31,0.46-2.58,0.71-4.19,0.71C210.99,67,207.81,65.03,207.81,59.17"
          />
          <path
            fill="#231F20"
            d="M223.42,58.86v-0.1c0-5.76,4.5-8.59,11.01-8.59c2.98,0,5.1,0.46,7.17,1.11v-0.66c0-3.79-2.32-5.81-6.62-5.81
		c-2.32,0-4.24,0.4-5.91,1.06c-0.35,0.1-0.66,0.15-0.96,0.15c-1.41,0-2.58-1.11-2.58-2.53c0-1.11,0.76-2.07,1.67-2.42
		c2.53-0.96,5.1-1.57,8.59-1.57c3.99,0,6.97,1.06,8.84,2.98c1.97,1.92,2.88,4.75,2.88,8.23v13.13c0,1.67-1.31,2.93-2.98,2.93
		c-1.77,0-2.98-1.21-2.98-2.58v-1.01c-1.82,2.17-4.6,3.89-8.69,3.89C227.87,67.1,223.42,64.22,223.42,58.86 M241.71,56.94v-1.82
		c-1.57-0.61-3.64-1.06-6.06-1.06c-3.94,0-6.26,1.67-6.26,4.45v0.1c0,2.58,2.27,4.04,5.2,4.04
		C238.63,62.65,241.71,60.33,241.71,56.94"
          />
          <path
            fill="#231F20"
            d="M253.9,59.17V45.12h-1.01c-1.47,0-2.63-1.16-2.63-2.63c0-1.46,1.16-2.63,2.63-2.63h1.01v-4.55
		c0-1.67,1.36-3.03,3.08-3.03c1.67,0,3.03,1.36,3.03,3.03v4.55h4.8c1.46,0,2.68,1.16,2.68,2.63c0,1.47-1.21,2.63-2.68,2.63h-4.8
		v13.08c0,2.37,1.21,3.33,3.28,3.33c0.71,0,1.31-0.15,1.52-0.15c1.36,0,2.58,1.11,2.58,2.53c0,1.11-0.76,2.02-1.62,2.37
		c-1.31,0.46-2.58,0.71-4.19,0.71C257.09,67,253.9,65.03,253.9,59.17"
          />
          <path
            fill="#65BC46"
            d="M270.61,32.7c0-1.82,1.52-2.98,3.49-2.98s3.48,1.16,3.48,2.98V33c0,1.82-1.51,3.03-3.48,3.03
		s-3.49-1.21-3.49-3.03V32.7z"
          />
          <path
            fill="#231F20"
            d="M271.06,42.65c0-1.72,1.31-3.08,3.03-3.08c1.72,0,3.08,1.36,3.08,3.08v21.12c0,1.72-1.36,3.03-3.08,3.03
		c-1.67,0-3.03-1.31-3.03-3.03V42.65z"
          />
          <path
            fill="#231F20"
            d="M281.59,53.36v-0.1c0-7.63,6.06-13.94,14.25-13.94c8.18,0,14.19,6.21,14.19,13.84v0.1
		c0,7.58-6.06,13.89-14.29,13.89C287.6,67.15,281.59,60.93,281.59,53.36 M303.92,53.36v-0.1c0-4.7-3.38-8.59-8.18-8.59
		c-4.9,0-8.03,3.84-8.03,8.49v0.1c0,4.65,3.38,8.54,8.13,8.54C300.79,61.79,303.92,57.95,303.92,53.36"
          />
          <path
            fill="#231F20"
            d="M314.49,42.65c0-1.72,1.31-3.08,3.03-3.08c1.72,0,3.08,1.36,3.08,3.08v1.31c1.72-2.48,4.19-4.65,8.33-4.65
		c6.01,0,9.5,4.04,9.5,10.2v14.25c0,1.72-1.31,3.03-3.03,3.03c-1.72,0-3.08-1.31-3.08-3.03V51.39c0-4.14-2.07-6.52-5.71-6.52
		c-3.54,0-6.01,2.48-6.01,6.62v12.27c0,1.72-1.36,3.03-3.08,3.03c-1.67,0-3.03-1.31-3.03-3.03V42.65z"
          />
          <path
            fill="#65BC46"
            d="M349.27,32.46c0-1.67,1.31-3.03,3.03-3.03c1.72,0,3.08,1.36,3.08,3.03v11.51c1.72-2.47,4.19-4.65,8.33-4.65
		c6.01,0,9.49,4.04,9.49,10.2v14.24c0,1.72-1.31,3.03-3.03,3.03c-1.72,0-3.08-1.31-3.08-3.03V51.39c0-4.14-2.07-6.51-5.7-6.51
		c-3.53,0-6.01,2.47-6.01,6.61v12.27c0,1.72-1.36,3.03-3.08,3.03c-1.67,0-3.03-1.31-3.03-3.03V32.46z"
          />
          <path
            fill="#65BC46"
            d="M377.38,53.36v-0.1c0-7.62,6.06-13.93,14.24-13.93c8.18,0,14.19,6.21,14.19,13.83v0.1
		c0,7.57-6.06,13.88-14.29,13.88C383.39,67.14,377.38,60.93,377.38,53.36 M399.69,53.36v-0.1c0-4.7-3.38-8.58-8.18-8.58
		c-4.9,0-8.03,3.84-8.03,8.48v0.1c0,4.64,3.38,8.53,8.13,8.53C396.56,61.79,399.69,57.95,399.69,53.36"
          />
          <path
            fill="#65BC46"
            d="M410.36,42.66c0-1.72,1.31-3.08,3.03-3.08c1.72,0,3.08,1.36,3.08,3.08v1.26c1.72-2.37,4.04-4.59,8.13-4.59
		c3.84,0,6.56,1.87,7.98,4.7c2.12-2.83,4.95-4.7,8.99-4.7c5.81,0,9.34,3.69,9.34,10.2v14.24c0,1.72-1.31,3.03-3.03,3.03
		c-1.72,0-3.08-1.31-3.08-3.03V51.39c0-4.24-1.97-6.51-5.4-6.51c-3.33,0-5.7,2.32-5.7,6.61v12.27c0,1.72-1.36,3.03-3.03,3.03
		c-1.72,0-3.08-1.31-3.08-3.03V51.34c0-4.14-2.02-6.46-5.4-6.46c-3.38,0-5.7,2.52-5.7,6.61v12.27c0,1.72-1.36,3.03-3.08,3.03
		c-1.67,0-3.03-1.31-3.03-3.03V42.66z"
          />
          <path
            fill="#65BC46"
            d="M468.98,67.14c-7.72,0-13.68-5.6-13.68-13.83v-0.1c0-7.62,5.4-13.88,13.02-13.88
		c8.48,0,12.67,6.97,12.67,13.13c0,1.72-1.31,2.93-2.88,2.93h-16.71c0.66,4.34,3.74,6.76,7.67,6.76c2.57,0,4.59-0.91,6.31-2.32
		c0.45-0.35,0.86-0.56,1.56-0.56c1.36,0,2.42,1.06,2.42,2.47c0,0.76-0.35,1.41-0.81,1.87C476.15,65.78,473.12,67.14,468.98,67.14
		 M475.04,51.34c-0.4-3.94-2.73-7.02-6.76-7.02c-3.74,0-6.36,2.88-6.92,7.02H475.04z"
          />
          <path
            fill="#65BC46"
            d="M484.45,63.86c-0.66-0.35-1.16-1.11-1.16-2.12c0-1.36,1.06-2.47,2.47-2.47c0.5,0,0.96,0.15,1.31,0.35
		c2.58,1.72,5.25,2.57,7.67,2.57c2.62,0,4.14-1.11,4.14-2.88v-0.1c0-2.07-2.83-2.78-5.96-3.73c-3.94-1.11-8.33-2.73-8.33-7.83v-0.1
		c0-5.05,4.19-8.13,9.49-8.13c2.83,0,5.81,0.81,8.38,2.17c0.86,0.45,1.46,1.26,1.46,2.32c0,1.41-1.11,2.47-2.52,2.47
		c-0.5,0-0.81-0.1-1.21-0.3c-2.17-1.11-4.39-1.82-6.26-1.82c-2.37,0-3.74,1.11-3.74,2.63V47c0,1.97,2.88,2.78,6.01,3.79
		c3.89,1.21,8.28,2.98,8.28,7.77v0.1c0,5.6-4.34,8.38-9.9,8.38C491.22,67.04,487.53,65.98,484.45,63.86"
          />
        </g>
      </svg>
    </LogoH1>
  );
};
LogoIH.propTypes = {
  width: PropTypes.number,
};
LogoIH.defaultProps = {
  width: 390,
};

export default LogoIH;
