.header__drawer-link {
  border: none;
  background: none;
  font-family: var(--font-primary), sans-serif;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 26px;
  line-height: 29px;
  font-weight: 500;
  font-size: 18px;
  color: var(--color-secondary);
  cursor: pointer;
  border-bottom: 2px solid var(--color-grey-100);
}

.header__drawer-link:hover {
  background-color: var(--color-grey-50);
  border-bottom: 2px solid var(--color-grey-200);
  color: var(--color-secondary);
}
