import React, { useState } from 'react';
import Hyperlink from '../Link';
import ChevronDown from '../../assets/chevronDown.svg';
import * as styles from './HeaderAccordion.module.scss';

const HeaderAccordion = ({ navigationItems, parentNavGroupTitle = '' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles['header-accordion']} ${isOpen ? styles.open : ''}`}>
      <button className={`${styles['header-accordion__label']}`} onClick={toggleIsOpen}>
        <span>{parentNavGroupTitle}</span>
        <div className={`${styles['header-accordion__icon']}`}>
          <img src={ChevronDown} />
        </div>
      </button>
      <ul>
        {navigationItems.map(({ props: { title, slug = '', identifier } }, index) => (
          <li key={index}>
            <Hyperlink href={slug} id={identifier} dataNavtype="header" dataNavgroup={parentNavGroupTitle}>
              {title}
            </Hyperlink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderAccordion;
