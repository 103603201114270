import React from 'react';
import Hyperlink from '../Link';
import * as styles from './HeaderSubmenu.module.scss';

const HeaderSubmenu = ({ parentNavGroupTitle, visible, items }) => {
  return (
    <div className={`${styles.headerSubmenu} ${visible ? styles.visible : ''}`}>
      <ul>
        {items.map(({ props: { title, slug = '', identifier } }, index) => (
          <li key={index}>
            <Hyperlink href={slug} id={identifier} dataNavtype="header" dataNavgroup={parentNavGroupTitle}>
              {title}
            </Hyperlink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderSubmenu;
