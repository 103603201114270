import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { EmitterContext } from './Emitter';
import { ServicesContext } from './Services';

export class Provider extends PureComponent {
  static propTypes = {
    services: PropTypes.object.isRequired,
    emitter: PropTypes.object.isRequired,
    children: PropTypes.any,
  };

  static defaultProps = {
    children: [],
  };

  render() {
    const { children, services, emitter } = this.props;
    return (
      <ServicesContext.Provider value={services}>
        <EmitterContext.Provider value={emitter}>{children}</EmitterContext.Provider>
      </ServicesContext.Provider>
    );
  }
}
export default Provider;
