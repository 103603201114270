import React from 'react';
// import styled from "styled-components";

import SelfShowingLayout from '../layouts/SelfShowing';
// layouts

import ShowingEdit from '../containers/ShowingEdit';
import EnsureAuthenticated from '../containers/EnsureAuthenticated';
// containers
export default (props) => (
  <EnsureAuthenticated
    pathName="/login"
    redirectTo="edit a showing"
    verificationRequired={['email', 'phone', 'first_name', 'last_name']}
  >
    <SelfShowingLayout px={[20, 30, 60]} py={40}>
      <ShowingEdit {...props} />
    </SelfShowingLayout>
  </EnsureAuthenticated>
);
