footer {
  display: flex;
  justify-content: center;
  background: var(--color-blue-dark);
  font-family: var(--font-primary), sans-serif;
  position: relative;
  z-index: var(--footer-z-index, initial);
}

.footer__grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 16px;
  padding: 16px 0;
  width: 100%;
  color: #4d4d4d;
}

.footer__grid--hide {
  display: none;
}

.footer__content {
  max-width: 736px;
  align-items: center;
}

.footer__accordion-group,
.footer__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer__accordion-group :global(.open.footer-accordion:first-child ul) {
  column-count: 2;
  display: block;
}

.footer__menu {
  grid-column: span 2 / span 2;
  grid-template-rows: auto auto;
}

.footer__menu:nth-child(1) {
  grid-area: 1 / 1 / 3 / 5;
}

.footer__menu:nth-child(1) .footer__menu--links {
  column-count: 2;
}

.footer__menu:nth-child(2) {
  grid-area: 1 / 5 / 1 / 7;
  margin-bottom: 40px;
}

.footer__menu:nth-child(3) {
  grid-area: 2 / 5 / 2 / 7;
}

.footer__menu--title {
  color: var(--color-secondary);
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 12px;
  margin: 0 28px 20px 0;
  border-bottom: rgba(196, 218, 233, 0.6) 1px solid;
  cursor: default;
}

.footer__menu--title,
.footer__menu--links {
  line-height: 18px;
}

.footer__menu--links {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.footer__menu--links li {
  padding: 4px 0;
  max-height: 18px;
}

.footer__menu:nth-child(6) .footer__menu--title {
  margin-right: 0;
}

@media (max-width: 1159px) {
  .footer__grid {
    display: none;
  }
}

@media (min-width: 1160px) {
  .footer__menu--links {
    display: block;
  }

  .footer__menu--links a {
    font-size: 14px;
  }

  .footer__content {
    padding-top: 24px;
    max-width: 1128px;
  }

  .footer__accordion-group {
    display: none;
  }

  .footer__accordion-group--show {
    display: flex;
  }
}
