import React, { Component } from 'react';
import PlaidIdentityVerification from '../../src/containers/PlaidIdentityVerification';
import { withServices } from '@invitation-homes/styled-ui/lib/providers/Services';
import { isTrusted } from '../../src/utils/users';
import VerificationFailed from '../../src/containers/PlaidIdentityVerification/VerificationFailed';

class EnsureTrusted extends Component {
  state = {
    isLoading: true,
    isTrusted: false,
    hasVerifiedPhoneNumber: false,
    verificationFailed: false,
    reachedMaximumFailedAttempts: false,
  };

  async componentDidMount() {
    const user = await this.props.auth.user(true);

    this.setState({
      isLoading: false,
      isTrusted: process.env.TESTING ? true : isTrusted(user.trusted_at),
      hasVerifiedPhoneNumber: !!user && !!user.phone && user.status_sms === 'verified',
    });
  }

  render() {
    const { isLoading, isTrusted, hasVerifiedPhoneNumber, verificationFailed, reachedMaximumFailedAttempts } =
      this.state;
    const { unit } = this.props;
    const unitId = unit.unit_id;
    const plaidEnabled = unit.market.fraud_verification_mode === 'plaid';
    const plaidTemplateId = unit.market.plaid_template_id;

    if (isLoading) {
      return null;
    }

    if (verificationFailed) {
      return <VerificationFailed unitId={unitId} allowRetry={!reachedMaximumFailedAttempts} />;
    }

    if (plaidEnabled && (!isTrusted || !hasVerifiedPhoneNumber)) {
      const onSuccessfulVerification = () => {
        window.location.replace(`/schedule-showing/${unitId}`);
      };
      const onFailedVerification = (reachedMaximumFailedAttempts = false) => {
        this.setState({
          verificationFailed: true,
          reachedMaximumFailedAttempts,
        });
      };
      return (
        <PlaidIdentityVerification
          templateId={plaidTemplateId}
          unit={unit}
          onSuccessfulVerification={onSuccessfulVerification}
          onFailedVerification={onFailedVerification}
        />
      );
    }

    return this.props.children;
  }
}

export default withServices(['auth'])(EnsureTrusted);
