import React, { Component } from 'react';
// Material UI
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';

// Styled UI
import CircleLoader from '@invitation-homes/styled-ui/lib/components/Loader/CircleLoader';
import Button from '@invitation-homes/styled-ui/lib/components/Button/Button';
import withUser from '@invitation-homes/styled-ui/lib/providers/Auth';

import axios from 'axios';

const styles = (theme) => ({
  formSection: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
    justifyContent: 'space-evenly',
    width: '430px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingBottom: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
});

class Form extends Component {
  state = {
    name: '',
    email: '',
    phone: '',

    errors: [],
    status: '',
    opened: true,
  };

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phone: user.phone,
      });
    }
  }

  onSubmit = async () => {
    const errors = this.validateFields();
    if (errors.length) {
      return;
    }
    this.setState({ status: 'sending' });
    const payload = {
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
      message: 'Lead submission form from Self-Tour Error Page',
      request_more_info: false,
      request_tour: true,
    };
    axios
      .post(`/api/v1/contact/${this.props.unitId}/lead`, { payload })
      .then((resp) => {
        if (resp.data.success) {
          this.setState({ status: 'sent' });
        } else this.setState({ status: 'failed' });
      })
      .catch(() => {
        this.setState({ status: 'failed' });
      });
  };

  validateFields() {
    const { phone, email } = this.state;
    const errors = [];
    if (!email.includes('@') || !email.includes('.')) {
      errors.push('email');
    }
    if (phone.replace(/[0-9]/g, '').length > 0 || phone.length < 10) {
      errors.push('phone');
    }
    this.setState({ errors });
    return errors;
  }

  onChange(key, e) {
    e.preventDefault();
    const { value } = e.target;
    this.setState({ [key]: value }, () => this.validateFields());
  }

  render() {
    const { phone, name, email, status, errors } = this.state;
    if (status === 'sending') {
      return (
        <div style={{ padding: '50px 0 40px' }}>
          <CircleLoader size={24} />
        </div>
      );
    }
    if (status === 'failed') {
      return (
        <div
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            padding: '20px 10px 20px',
            border: '1px red solid',
          }}
        >
          <div>Sorry we couldn't process your request.</div>
          <div style={{ textAlign: 'center' }}>Please call (888) 990-4684</div>
        </div>
      );
    }
    if (status === 'sent') {
      return (
        <div
          style={{
            marginTop: '20px',
            padding: '0px 10px 20px',
            border: '1px green solid',
          }}
        >
          <h3 style={{ color: 'green' }}>Success!</h3>
          <div>Your information has been submitted.</div>
        </div>
      );
    }
    return (
      <Grid container classes={{ container: this.props.classes.formSection }}>
        {!status && (
          <React.Fragment>
            <TextField
              value={name}
              onChange={(e) => this.onChange('name', e)}
              label="Full Name"
              variant="outlined"
              id="mui-theme-provider-outlined-input"
              required={true}
              helperText={!name ? 'Please enter your name' : ''}
              error={!name || errors.includes('name')}
            />
            <TextField
              value={email}
              onChange={(e) => this.onChange('email', e)}
              label="Email Address"
              type="email"
              variant="outlined"
              helperText={!email || errors.includes('email') ? 'Please enter a valid email' : ''}
              id="mui-theme-provider-outlined-input"
              required={true}
              error={!email || errors.includes('email')}
            />
            <TextField
              value={phone}
              onChange={(e) => this.onChange('phone', e)}
              label="Phone Number"
              type="integer"
              variant="outlined"
              helperText={!phone || errors.includes('phone') ? 'Please enter a valid phone number' : ''}
              id="mui-theme-provider-outlined-input"
              required={true}
              error={!phone || errors.includes('phone')}
            />
            <Button
              color="primary"
              fontSize="15px"
              width="200px"
              variant="contained"
              pt="12"
              pb="12"
              onClick={() => this.onSubmit()}
              ml="auto"
              mr="auto"
              disabled={!name || !email || !phone}
            >
              Submit
            </Button>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

export default withUser(withStyles(styles)(Form));
